export const MARQUE_LIST_REQUEST = "MARQUE_LIST_REQUEST";
export const MARQUE_LIST_SUCCESS = "MARQUE_LIST_SUCCESS";
export const MARQUE_LIST_FAIL = "MARQUE_LIST_FAIL";

export const MARQUE_ADD_REQUEST = "MARQUE_ADD_REQUEST";
export const MARQUE_ADD_SUCCESS = "MARQUE_ADD_SUCCESS";
export const MARQUE_ADD_FAIL = "MARQUE_ADD_FAIL";

export const MARQUE_DELETE_REQUEST = "MARQUE_DELETE_REQUEST";
export const MARQUE_DELETE_SUCCESS = "MARQUE_DELETE_SUCCESS";
export const MARQUE_DELETE_FAIL = "MARQUE_DELETE_FAIL";
