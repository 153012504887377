import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailClient, updateClient } from "../../redux/actions/clientActions";
import CountrySelector from "../../components/Selector";
import { COUNTRIES, baseURLFile } from "../../constants";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import InputModel from "../../components/InputModel";
import ConfirmationModal from "../../components/ConfirmationModal";
import LayoutSection from "../../components/LayoutSection";

const LayoutClientSection = (props) => {
  return (
    <div className=" border border-gray rounded-md rounded-t-xl shadow-2 my-2">
      <div className="p-2 rounded-t-xl bg-gray ">{props.title}</div>
      <div className="p-4 bg-white rounded-b-xl">{props.children}</div>
    </div>
  );
};

function EditClientScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();
  //
  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");

  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const [otherPhone, setOtherPhone] = useState("");
  const [otherPhoneError, setOtherPhoneError] = useState("");

  const [dateNaissance, setDateNaissance] = useState("");
  const [errorDateNaissance, setErrorDateNaissance] = useState("");

  const [langue, setLangue] = useState("");
  const [langueError, setLangueError] = useState("");

  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState("");

  const [type, setType] = useState("");
  const [typeError, setTypeError] = useState("");

  const [isAdd, setIsAdd] = useState(false);
  const [eventType, setEventType] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const clientDetail = useSelector((state) => state.detailClient);
  const { loading, error, success, client } = clientDetail;

  const clientUpdate = useSelector((state) => state.updateClient);
  const { loadingClientUpdate, errorClientUpdate, successClientUpdate } =
    clientUpdate;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailClient(id));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (client !== undefined && client !== null) {
      setFirstName(client.first_name);

      setLastName(client.last_name);

      setEmail(client.email);

      setPhone(client.phone);

      setOtherPhone(client.other_phone);

      setDateNaissance(client.date_birth);

      setLangue(client.langue);

      setStatus("");

      setType("");
    }
  }, [client]);

  useEffect(() => {
    if (successClientUpdate) {
      setFirstName("");

      setLastName("");

      setEmail("");

      setPhone("");

      setOtherPhone("");

      setDateNaissance("");

      setLangue("");

      setStatus("");

      setType("");
      dispatch(detailClient(id));

      setIsAdd(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successClientUpdate]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/clients/">
            <div className="">Clients</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Modifier</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Modifier le client
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className=" w-full px-1 py-1">
              <LayoutSection
                title="Informations personnelles"
                styles="bg-primary text-white font-bold"
              >
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Email*"
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={(v) => setEmail(v.target.value)}
                    error={emailError}
                  />
                </div>
                {/* fisrt name & last name */}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Nom*"
                    type="text"
                    placeholder=""
                    value={firstName}
                    onChange={(v) => setFirstName(v.target.value)}
                    error={errorFirstName}
                  />

                  <InputModel
                    label="Prénom"
                    type="text"
                    placeholder=""
                    value={lastName}
                    onChange={(v) => setLastName(v.target.value)}
                    error={errorLastName}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Mobile"
                    type="text"
                    placeholder=""
                    value={otherPhone}
                    onChange={(v) => setOtherPhone(v.target.value)}
                    error={otherPhoneError}
                  />
                  <InputModel
                    label="Téléphone"
                    type="text"
                    placeholder=""
                    value={phone}
                    onChange={(v) => setPhone(v.target.value)}
                    error={errorPhone}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Langue"
                    type="text"
                    placeholder=""
                    value={langue}
                    onChange={(v) => setLangue(v.target.value)}
                    error={langueError}
                  />
                </div>

                {/* <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Statut"
                    type="text"
                    placeholder=""
                    value={status}
                    onChange={(v) => setStatus(v.target.value)}
                    error={statusError}
                  />
                </div> */}

                {/* date and nation */}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Date de naissance"
                    type="date"
                    placeholder=""
                    value={dateNaissance}
                    onChange={(v) => {
                      if (v.target.value !== "") {
                        const parsedDate = new Date(v.target.value);
                        setDateNaissance(
                          parsedDate.toISOString().split("T")[0]
                        );
                      }
                    }}
                    error={errorDateNaissance}
                  />
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAdd("true");
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Annuler
            </button>
            <button
              onClick={async () => {
                var check = true;

                setErrorFirstName("");
                setErrorLastName("");
                setEmailError("");
                setErrorPhone("");
                setOtherPhoneError("");
                setErrorDateNaissance("");
                setLangueError("");
                setStatusError("");
                setTypeError("");

                if (firstName === "") {
                  setErrorFirstName("Ce champ est requis.");
                  check = false;
                }
                if (email === "") {
                  setEmailError("Ce champ est requis.");
                  check = false;
                }

                if (check) {
                  setEventType("add");
                  setIsAdd(true);
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                />
              </svg>
              Modifié
            </button>
          </div>
        </div>
        {/*  */}
        <ConfirmationModal
          isOpen={isAdd}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir modifier ce client ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setFirstName("");
              setErrorFirstName("");

              setLastName("");
              setErrorLastName("");

              setEmail("");
              setEmailError("");

              setPhone("");
              setErrorPhone("");

              setOtherPhone("");
              setOtherPhoneError("");

              setDateNaissance("");
              setErrorDateNaissance("");

              setLangue("");
              setLangueError("");

              setStatus("");
              setStatusError("");

              setType("");
              setTypeError("");

              dispatch(detailClient(id));

              setIsAdd(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                updateClient(id, {
                  first_name: firstName ?? "",
                  last_name: lastName ?? "",
                  date_birth: dateNaissance ?? "",
                  other_phone: otherPhone ?? null,
                  phone: phone ?? "",
                  email: email ?? "",
                  langue: langue ?? "",
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            }
          }}
          onCancel={() => {
            setIsAdd(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditClientScreen;
