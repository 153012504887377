export const MODEL_LIST_REQUEST = "MODEL_LIST_REQUEST";
export const MODEL_LIST_SUCCESS = "MODEL_LIST_SUCCESS";
export const MODEL_LIST_FAIL = "MODEL_LIST_FAIL";

export const MODEL_ADD_REQUEST = "MODEL_ADD_REQUEST";
export const MODEL_ADD_SUCCESS = "MODEL_ADD_SUCCESS";
export const MODEL_ADD_FAIL = "MODEL_ADD_FAIL";

export const MODEL_DELETE_REQUEST = "MODEL_DELETE_REQUEST";
export const MODEL_DELETE_SUCCESS = "MODEL_DELETE_SUCCESS";
export const MODEL_DELETE_FAIL = "MODEL_DELETE_FAIL";
