import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { COUNTRIES } from "../../constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InputModel from "../../components/InputModel";
import LayoutSection from "../../components/LayoutSection";
import ConfirmationModal from "../../components/ConfirmationModal";
import {
  addNewPrescriber,
  detailPrescriber,
  updatePrescriber,
} from "../../redux/actions/prescriberActions";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

function EditPrescripteurScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { id } = useParams();
  //
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");

  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");

  const [society, setSociety] = useState("");
  const [societyError, setSocietyError] = useState("");

  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const [otherPhone, setOtherPhone] = useState("");
  const [otherPhoneError, setOtherPhoneError] = useState("");

  const [fax, setFax] = useState("");
  const [faxError, setFaxError] = useState("");

  const [country, setCountry] = useState("MA");
  const [errorCountry, setErrorCountry] = useState("");

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  const [commissionType, setCommissionType] = useState(1);
  const [commissionTypeError, setCommissionTypeError] = useState("");

  const [commission, setCommission] = useState(0);
  const [commissionError, setCommissionError] = useState("");

  const [isAdd, setIsAdd] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const prescriberUpdate = useSelector((state) => state.updatePrescriber);
  const {
    loadingPrescriberUpdate,
    errorPrescriberUpdate,
    successPrescriberUpdate,
  } = prescriberUpdate;

  const prescriberDetail = useSelector((state) => state.detailPrescriber);
  const {
    loadingDetailPrescriber,
    errorDetailPrescriber,
    prescriber,
    successDetailPrescriber,
  } = prescriberDetail;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailPrescriber(id));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (prescriber !== undefined && prescriber !== null) {
      setEmail(prescriber.email);
      setFirstName(prescriber.first_name);
      setLastName(prescriber.last_name);
      setSociety(prescriber.company);
      setPhone(prescriber.phone);
      setOtherPhone(prescriber.other_phone);
      setFax(prescriber.fax);
      setCountry(prescriber.country);
      setCity(prescriber.city);
      setAddress(prescriber.address);
      setCommissionType(prescriber.commission_type);
      setCommission(prescriber.commission);
    }
  }, [prescriber]);

  useEffect(() => {
    if (successPrescriberUpdate) {
      setEmail("");
      setEmailError("");
      setFirstName("");
      setErrorFirstName("");
      setLastName("");
      setErrorLastName("");
      setSociety("");
      setSocietyError("");
      setPhone("");
      setErrorPhone("");
      setOtherPhone("");
      setOtherPhoneError("");
      setFax("");
      setFaxError("");
      setCountry("MA");
      setErrorCountry("");
      setCity("");
      setCityError("");
      setAddress("");
      setAddressError("");
      setCommissionType(1);
      setCommissionTypeError("");
      setCommission(0);
      setCommissionError("");
      dispatch(detailPrescriber(id));
      setIsAdd(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successPrescriberUpdate]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/prescripteurs/">
            <div className="">Prescripteurs</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Nouveau</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Ajouter un nouveau prescripteur
            </h4>
          </div>

          <div className="my-2">
            {/* { <Alert type="error" message={errorDetailPrescriber} />} */}
          </div>
          {/*  */}
          {loadingDetailPrescriber ? (
            <Loader />
          ) : errorDetailPrescriber ? (
            <Alert type="error" message={errorDetailPrescriber} />
          ) : (
            <>
              <div className="flex md:flex-row flex-col ">
                <div className="md:w-1/2 w-full px-1 py-1">
                  <LayoutSection
                    title="Informations personnelles"
                    styles="bg-danger text-white font-bold"
                  >
                    {/* email */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Email*"
                        type="text"
                        placeholder=""
                        value={email}
                        onChange={(v) => setEmail(v.target.value)}
                        error={emailError}
                      />
                    </div>
                    {/* fisrt name & last name */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Nom*"
                        type="text"
                        placeholder=""
                        value={firstName}
                        onChange={(v) => setFirstName(v.target.value)}
                        error={errorFirstName}
                      />
                      <InputModel
                        label="Prénom"
                        type="text"
                        placeholder=""
                        value={lastName}
                        onChange={(v) => setLastName(v.target.value)}
                        error={errorLastName}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Société"
                        type="text"
                        placeholder=""
                        value={society}
                        onChange={(v) => setSociety(v.target.value)}
                        error={societyError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Téléphone"
                        type="text"
                        placeholder=""
                        value={phone}
                        onChange={(v) => setPhone(v.target.value)}
                        error={errorPhone}
                      />
                      <InputModel
                        label="Mobile"
                        type="text"
                        placeholder=""
                        value={otherPhone}
                        onChange={(v) => setOtherPhone(v.target.value)}
                        error={otherPhoneError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Fax"
                        type="text"
                        placeholder=""
                        value={fax}
                        onChange={(v) => setFax(v.target.value)}
                        error={faxError}
                      />
                    </div>

                    {/* date and nation */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Pays"
                        type="select"
                        placeholder=""
                        value={country}
                        onChange={(v) => {
                          setCountry(v.target.value);
                        }}
                        error={errorCountry}
                        options={COUNTRIES?.map((country) => ({
                          value: country.value,
                          label: country.title,
                        }))}
                      />
                    </div>

                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Ville"
                        type="text"
                        placeholder=""
                        value={city}
                        onChange={(v) => setCity(v.target.value)}
                        error={cityError}
                      />
                    </div>
                    {/* address */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Adressse"
                        type="textarea"
                        placeholder=""
                        value={address}
                        onChange={(v) => setAddress(v.target.value)}
                        error={addressError}
                      />
                    </div>
                  </LayoutSection>
                </div>
                <div className="md:w-1/2 w-full px-1 py-1">
                  <LayoutSection
                    title="Commission"
                    styles="bg-primary text-white font-bold"
                  >
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Type"
                        type="select"
                        placeholder=""
                        value={commissionType}
                        onChange={(v) => {
                          setCommissionType(v.target.value);
                        }}
                        error={commissionTypeError}
                        options={[
                          { value: 1, label: "Variables" },
                          { value: 2, label: "Fixes" },
                        ]}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Commission (% sur Montant déclaré)"
                        type="number"
                        placeholder=""
                        value={commission}
                        onChange={(v) => {
                          setCommission(v.target.value);
                        }}
                        error={commissionError}
                      />
                    </div>
                  </LayoutSection>
                </div>
              </div>
              <div className="my-2 flex flex-row items-center justify-end">
                <button
                  onClick={() => {
                    setEventType("cancel");
                    setIsAdd(true);
                  }}
                  className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                >
                  Annuler
                </button>
                <button
                  onClick={async () => {
                    var check = true;

                    setEmailError("");
                    setErrorFirstName("");
                    setErrorLastName("");
                    setSocietyError("");
                    setErrorPhone("");
                    setOtherPhoneError("");
                    setFaxError("");
                    setErrorCountry("");
                    setCityError("");
                    setAddressError("");
                    setCommissionTypeError("");
                    setCommissionError("");
                    //
                    if (email === "") {
                      setEmailError("Ce champ est requis.");
                      check = false;
                    }
                    if (firstName === "") {
                      setErrorFirstName("Ce champ est requis.");
                      check = false;
                    }
                    if (commissionType === "") {
                      setCommissionTypeError("Ce champ est requis.");
                      check = false;
                    }
                    if (commission === "") {
                      setCommissionError("Ce champ est requis.");
                      check = false;
                    }

                    if (check) {
                      setEventType("update");
                      setIsAdd(true);
                    } else {
                      toast.error(
                        "Certains champs sont obligatoires veuillez vérifier"
                      );
                    }
                  }}
                  className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                    />
                  </svg>
                  Modifié
                </button>
              </div>
            </>
          )}
        </div>

        {/*  */}
        <ConfirmationModal
          isOpen={isAdd}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette informations ?"
              : "Êtes-vous sûr de vouloir modifier ce prescripteur ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setEmail("");
              setEmailError("");

              setFirstName("");
              setErrorFirstName("");

              setLastName("");
              setErrorLastName("");

              setSociety("");
              setSocietyError("");

              setPhone("");
              setErrorPhone("");

              setOtherPhone("");
              setOtherPhoneError("");

              setFax("");
              setFaxError("");

              setCountry("MA");
              setErrorCountry("");

              setCity("");
              setCityError("");

              setAddress("");
              setAddressError("");

              setCommissionType(1);
              setCommissionTypeError("");

              setCommission(0);
              setCommissionError("");
              dispatch(detailPrescriber(id));
              setIsAdd(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                updatePrescriber(id, {
                  first_name: firstName,
                  last_name: lastName ?? "",
                  email: email,
                  company: society ?? "",
                  phone: phone ?? "",
                  other_phone: otherPhone ?? "",
                  fax: fax ?? "",
                  country: country ?? "",
                  city: city ?? "",
                  address: address ?? "",
                  commission_type: commissionType,
                  commission: commission,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            }
          }}
          onCancel={() => {
            setIsAdd(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditPrescripteurScreen;
