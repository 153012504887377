import { toast } from "react-toastify";

import {
  MODEL_LIST_REQUEST,
  MODEL_LIST_SUCCESS,
  MODEL_LIST_FAIL,
  //
  MODEL_DELETE_REQUEST,
  MODEL_DELETE_SUCCESS,
  MODEL_DELETE_FAIL,
  //
  MODEL_ADD_REQUEST,
  MODEL_ADD_SUCCESS,
  MODEL_ADD_FAIL,
  //
} from "../constants/modelContants";

export const addNewModeleReducer = (state = {}, action) => {
  switch (action.type) {
    case MODEL_ADD_REQUEST:
      return { loadingModelAdd: true };
    case MODEL_ADD_SUCCESS:
      toast.success("Cette Modele a été ajouté avec succès");
      return {
        loadingModelAdd: false,
        successModelAdd: true,
      };
    case MODEL_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingModelAdd: false,
        successModelAdd: false,
        errorModelAdd: action.payload,
      };
    default:
      return state;
  }
};

export const deleteModelReducer = (state = {}, action) => {
  switch (action.type) {
    case MODEL_DELETE_REQUEST:
      return { loadingModelDelete: true };
    case MODEL_DELETE_SUCCESS:
      toast.success("Cette modele a été supprimé avec succès");
      return {
        loadingModelDelete: false,
        successModelDelete: true,
      };
    case MODEL_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingModelDelete: false,
        successModelDelete: false,
        errorModelDelete: action.payload,
      };
    default:
      return state;
  }
};

export const modelListReducer = (state = { models: [] }, action) => {
  switch (action.type) {
    case MODEL_LIST_REQUEST:
      return { loadingModel: true, models: [] };
    case MODEL_LIST_SUCCESS:
      return {
        loadingModel: false,
        models: action.payload,
      };
    case MODEL_LIST_FAIL:
      return { loadingModel: false, errorModel: action.payload };
    default:
      return state;
  }
};
