import { toast } from "react-toastify";

import {
  EMPLOYE_LIST_REQUEST,
  EMPLOYE_LIST_SUCCESS,
  EMPLOYE_LIST_FAIL,
  //
  EMPLOYE_ADD_REQUEST,
  EMPLOYE_ADD_SUCCESS,
  EMPLOYE_ADD_FAIL,
  //
  EMPLOYE_DETAIL_REQUEST,
  EMPLOYE_DETAIL_SUCCESS,
  EMPLOYE_DETAIL_FAIL,
  //
  EMPLOYE_UPDATE_REQUEST,
  EMPLOYE_UPDATE_SUCCESS,
  EMPLOYE_UPDATE_FAIL,
  //
} from "../constants/employeConstants";

export const updateEmployeReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYE_UPDATE_REQUEST:
      return { loadingEmployeUpdate: true };
    case EMPLOYE_UPDATE_SUCCESS:
      toast.success("Ce Employé a été mis à jour avec succès");
      return {
        loadingEmployeUpdate: false,
        successEmployeUpdate: true,
      };
    case EMPLOYE_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingEmployeUpdate: false,
        successEmployeUpdate: false,
        errorEmployeUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const detailEmployeReducer = (state = { employe: {} }, action) => {
  switch (action.type) {
    case EMPLOYE_DETAIL_REQUEST:
      return { loading: true };
    case EMPLOYE_DETAIL_SUCCESS:
      return {
        loading: false,
        success: true,
        employe: action.payload,
      };
    case EMPLOYE_DETAIL_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createNewEmployeReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYE_ADD_REQUEST:
      return { loadingEmployeAdd: true };
    case EMPLOYE_ADD_SUCCESS:
      toast.success("Ce Employé a été ajouté avec succès");
      return {
        loadingEmployeAdd: false,
        successEmployeAdd: true,
      };
    case EMPLOYE_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingEmployeAdd: false,
        successEmployeAdd: false,
        errorEmployeAdd: action.payload,
      };
    default:
      return state;
  }
};

export const employesListReducer = (state = { employes: [] }, action) => {
  switch (action.type) {
    case EMPLOYE_LIST_REQUEST:
      return { loadingEmploye: true, employes: [] };
    case EMPLOYE_LIST_SUCCESS:
      return {
        loadingEmploye: false,
        employes: action.payload.employes,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case EMPLOYE_LIST_FAIL:
      return { loadingEmploye: false, errorEmploye: action.payload };
    default:
      return state;
  }
};
