export const AGENCE_LIST_REQUEST = "AGENCE_LIST_REQUEST";
export const AGENCE_LIST_SUCCESS = "AGENCE_LIST_SUCCESS";
export const AGENCE_LIST_FAIL = "AGENCE_LIST_FAIL";

export const AGENCE_ADD_REQUEST = "AGENCE_ADD_REQUEST";
export const AGENCE_ADD_SUCCESS = "AGENCE_ADD_SUCCESS";
export const AGENCE_ADD_FAIL = "AGENCE_ADD_FAIL";

export const AGENCE_DETAIL_REQUEST = "AGENCE_DETAIL_REQUEST";
export const AGENCE_DETAIL_SUCCESS = "AGENCE_DETAIL_SUCCESS";
export const AGENCE_DETAIL_FAIL = "AGENCE_DETAIL_FAIL";

export const AGENCE_UPDATE_REQUEST = "AGENCE_UPDATE_REQUEST";
export const AGENCE_UPDATE_SUCCESS = "AGENCE_UPDATE_SUCCESS";
export const AGENCE_UPDATE_FAIL = "AGENCE_UPDATE_FAIL";
