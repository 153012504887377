import {
  CHARGE_LIST_REQUEST,
  CHARGE_LIST_SUCCESS,
  CHARGE_LIST_FAIL,
  //
  CHARGE_ADD_REQUEST,
  CHARGE_ADD_SUCCESS,
  CHARGE_ADD_FAIL,
  //
  CHARGE_DELETE_REQUEST,
  CHARGE_DELETE_SUCCESS,
  CHARGE_DELETE_FAIL,
  //
  CHARGE_UPDATE_REQUEST,
  CHARGE_UPDATE_SUCCESS,
  CHARGE_UPDATE_FAIL,
  //
  ENTRETIEN_LIST_REQUEST,
  ENTRETIEN_LIST_SUCCESS,
  ENTRETIEN_LIST_FAIL,
  //
  ENTRETIEN_DELETE_REQUEST,
  ENTRETIEN_DELETE_SUCCESS,
  ENTRETIEN_DELETE_FAIL,
  //
  ENTRETIEN_ADD_REQUEST,
  ENTRETIEN_ADD_SUCCESS,
  ENTRETIEN_ADD_FAIL,
  //
  ENTRETIEN_UPDATE_REQUEST,
  ENTRETIEN_UPDATE_SUCCESS,
  ENTRETIEN_UPDATE_FAIL,
  //
  DEPENSE_CHARGE_LIST_REQUEST,
  DEPENSE_CHARGE_LIST_SUCCESS,
  DEPENSE_CHARGE_LIST_FAIL,
  //
  DEPENSE_CHARGE_ADD_REQUEST,
  DEPENSE_CHARGE_ADD_SUCCESS,
  DEPENSE_CHARGE_ADD_FAIL,
  //
  DEPENSE_CHARGE_DETAIL_REQUEST,
  DEPENSE_CHARGE_DETAIL_SUCCESS,
  DEPENSE_CHARGE_DETAIL_FAIL,
  //
  DEPENSE_CHARGE_UPDATE_REQUEST,
  DEPENSE_CHARGE_UPDATE_SUCCESS,
  DEPENSE_CHARGE_UPDATE_FAIL,
  //
  DEPENSE_ENTRETIEN_LIST_REQUEST,
  DEPENSE_ENTRETIEN_LIST_SUCCESS,
  DEPENSE_ENTRETIEN_LIST_FAIL,
  //
  DEPENSE_ENTRETIEN_ADD_REQUEST,
  DEPENSE_ENTRETIEN_ADD_SUCCESS,
  DEPENSE_ENTRETIEN_ADD_FAIL,
  //
  DEPENSE_ENTRETIEN_DETAIL_REQUEST,
  DEPENSE_ENTRETIEN_DETAIL_SUCCESS,
  DEPENSE_ENTRETIEN_DETAIL_FAIL,
  //
  DEPENSE_ENTRETIEN_UPDATE_REQUEST,
  DEPENSE_ENTRETIEN_UPDATE_SUCCESS,
  DEPENSE_ENTRETIEN_UPDATE_FAIL,
  //
  DEPENSE_EMPLOYE_LIST_REQUEST,
  DEPENSE_EMPLOYE_LIST_SUCCESS,
  DEPENSE_EMPLOYE_LIST_FAIL,
  //
  DEPENSE_EMPLOYE_ADD_REQUEST,
  DEPENSE_EMPLOYE_ADD_SUCCESS,
  DEPENSE_EMPLOYE_ADD_FAIL,
  //
  DEPENSE_EMPLOYE_DETAIL_REQUEST,
  DEPENSE_EMPLOYE_DETAIL_SUCCESS,
  DEPENSE_EMPLOYE_DETAIL_FAIL,
  //
  DEPENSE_EMPLOYE_UPDATE_REQUEST,
  DEPENSE_EMPLOYE_UPDATE_SUCCESS,
  DEPENSE_EMPLOYE_UPDATE_FAIL,
  //
} from "../constants/designationConstants";

import { toast } from "react-toastify";

// depense employe

export const updateDepenseEmployeReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPENSE_EMPLOYE_UPDATE_REQUEST:
      return { loadingDepenseEmployeUpdate: true };
    case DEPENSE_EMPLOYE_UPDATE_SUCCESS:
      toast.success("Cette Employe a été modifé avec succès");
      return {
        loadingDepenseEmployeUpdate: false,
        successDepenseEmployeUpdate: true,
      };
    case DEPENSE_EMPLOYE_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingDepenseEmployeUpdate: false,
        successDepenseEmployeUpdate: false,
        errorDepenseEmployeUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const getDetailDepenseEmployeReducer = (
  state = { depenseEmploye: {} },
  action
) => {
  switch (action.type) {
    case DEPENSE_EMPLOYE_DETAIL_REQUEST:
      return { loadingDepenseEmployeDetail: true };
    case DEPENSE_EMPLOYE_DETAIL_SUCCESS:
      return {
        loadingDepenseEmployeDetail: false,
        successDepenseEmployeDetail: true,
        depenseEmploye: action.payload,
      };
    case DEPENSE_EMPLOYE_DETAIL_FAIL:
      return {
        loadingDepenseEmployeDetail: false,
        successDepenseEmployeDetail: false,
        errorDepenseEmployeDetail: action.payload,
      };
    default:
      return state;
  }
};

export const createNewDepenseEmployeReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPENSE_EMPLOYE_ADD_REQUEST:
      return { loadingDepenseEmployeAdd: true };
    case DEPENSE_EMPLOYE_ADD_SUCCESS:
      toast.success("Cette Charge Employé a été ajouté avec succès");
      return {
        loadingDepenseEmployeAdd: false,
        successDepenseEmployeAdd: true,
      };
    case DEPENSE_EMPLOYE_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingDepenseEmployeAdd: false,
        successDepenseEmployeAdd: false,
        errorDepenseEmployeAdd: action.payload,
      };
    default:
      return state;
  }
};

export const depenseEmployeListReducer = (
  state = { depenseEmployes: [] },
  action
) => {
  switch (action.type) {
    case DEPENSE_EMPLOYE_LIST_REQUEST:
      return { loadingDepenseEmploye: true, depenseEmployes: [] };
    case DEPENSE_EMPLOYE_LIST_SUCCESS:
      return {
        loadingDepenseEmploye: false,
        successDepenseEmploye: true,
        depenseEmployes: action.payload,
      };
    case DEPENSE_EMPLOYE_LIST_FAIL:
      return {
        loadingDepenseEmploye: false,
        successDepenseEmploye: false,
        errorDepenseEmploye: action.payload,
      };
    default:
      return state;
  }
};

// depense entretien

export const updateDepenseEntretienReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPENSE_ENTRETIEN_UPDATE_REQUEST:
      return { loadingDepenseEntretienUpdate: true };
    case DEPENSE_ENTRETIEN_UPDATE_SUCCESS:
      toast.success("Cette Entretien a été modifé avec succès");
      return {
        loadingDepenseEntretienUpdate: false,
        successDepenseEntretienUpdate: true,
      };
    case DEPENSE_ENTRETIEN_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingDepenseEntretienUpdate: false,
        successDepenseEntretienUpdate: false,
        errorDepenseEntretienUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const getDetailDepenseEntretienReducer = (
  state = { depenseEntretien: {} },
  action
) => {
  switch (action.type) {
    case DEPENSE_ENTRETIEN_DETAIL_REQUEST:
      return { loadingDepenseEntretienDetail: true };
    case DEPENSE_ENTRETIEN_DETAIL_SUCCESS:
      return {
        loadingDepenseEntretienDetail: false,
        successDepenseEntretienDetail: true,
        depenseEntretien: action.payload,
      };
    case DEPENSE_ENTRETIEN_DETAIL_FAIL:
      return {
        loadingDepenseEntretienDetail: false,
        successDepenseEntretienDetail: false,
        errorDepenseEntretienDetail: action.payload,
      };
    default:
      return state;
  }
};

export const createNewDepenseEntretienReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPENSE_ENTRETIEN_ADD_REQUEST:
      return { loadingDepenseEntretienAdd: true };
    case DEPENSE_ENTRETIEN_ADD_SUCCESS:
      toast.success("Cette Entretien a été ajouté avec succès");
      return {
        loadingDepenseEntretienAdd: false,
        successDepenseEntretienAdd: true,
      };
    case DEPENSE_ENTRETIEN_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingDepenseEntretienAdd: false,
        successDepenseEntretienAdd: false,
        errorDepenseEntretienAdd: action.payload,
      };
    default:
      return state;
  }
};

export const depenseEntretienListReducer = (
  state = { depenseEntretiens: [] },
  action
) => {
  switch (action.type) {
    case DEPENSE_ENTRETIEN_LIST_REQUEST:
      return { loadingDepenseEntretien: true, depenseEntretiens: [] };
    case DEPENSE_ENTRETIEN_LIST_SUCCESS:
      return {
        loadingDepenseEntretien: false,
        successDepenseEntretien: true,
        depenseEntretiens: action.payload,
      };
    case DEPENSE_ENTRETIEN_LIST_FAIL:
      return {
        loadingDepenseEntretien: false,
        successDepenseEntretien: false,
        errorDepenseEntretien: action.payload,
      };
    default:
      return state;
  }
};

// depense charge
export const updateDepenseChargeReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPENSE_CHARGE_UPDATE_REQUEST:
      return { loadingDepenseChargeUpdate: true };
    case DEPENSE_CHARGE_UPDATE_SUCCESS:
      toast.success("Cette Charge a été modifé avec succès");
      return {
        loadingDepenseChargeUpdate: false,
        successDepenseChargeUpdate: true,
      };
    case DEPENSE_CHARGE_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingDepenseChargeUpdate: false,
        successDepenseChargeUpdate: false,
        errorDepenseChargeUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const getDetailDepenseChargeReducer = (
  state = { depenseCharge: {} },
  action
) => {
  switch (action.type) {
    case DEPENSE_CHARGE_DETAIL_REQUEST:
      return { loadingDepenseChargeDetail: true };
    case DEPENSE_CHARGE_DETAIL_SUCCESS:
      return {
        loadingDepenseChargeDetail: false,
        successDepenseChargeDetail: true,
        depenseCharge: action.payload,
      };
    case DEPENSE_CHARGE_DETAIL_FAIL:
      return {
        loadingDepenseChargeDetail: false,
        successDepenseChargeDetail: false,
        errorDepenseChargeDetail: action.payload,
      };
    default:
      return state;
  }
};

export const createNewDepenseChargeReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPENSE_CHARGE_ADD_REQUEST:
      return { loadingDepenseChargeAdd: true };
    case DEPENSE_CHARGE_ADD_SUCCESS:
      toast.success("Cette Charge a été ajouté avec succès");
      return {
        loadingDepenseChargeAdd: false,
        successDepenseChargeAdd: true,
      };
    case DEPENSE_CHARGE_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingDepenseChargeAdd: false,
        successDepenseChargeAdd: false,
        errorDepenseChargeAdd: action.payload,
      };
    default:
      return state;
  }
};

export const depenseChargeListReducer = (
  state = { depenseCharges: [] },
  action
) => {
  switch (action.type) {
    case DEPENSE_CHARGE_LIST_REQUEST:
      return { loadingDepenseCharge: true, depenseCharges: [] };
    case DEPENSE_CHARGE_LIST_SUCCESS:
      return {
        loadingDepenseCharge: false,
        successDepenseCharge: true,
        depenseCharges: action.payload,
      };
    case DEPENSE_CHARGE_LIST_FAIL:
      return {
        loadingDepenseCharge: false,
        successDepenseCharge: false,
        errorDepenseCharge: action.payload,
      };
    default:
      return state;
  }
};

//

export const updateEntretienReducer = (state = {}, action) => {
  switch (action.type) {
    case ENTRETIEN_UPDATE_REQUEST:
      return { loadingEntretienUpdate: true };
    case ENTRETIEN_UPDATE_SUCCESS:
      toast.success("Ce Entretien a été modifé avec succès");
      return {
        loadingEntretienUpdate: false,
        successEntretienUpdate: true,
      };
    case ENTRETIEN_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingEntretienUpdate: false,
        successEntretienUpdate: false,
        errorEntretienUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const createNewEntretienReducer = (state = {}, action) => {
  switch (action.type) {
    case ENTRETIEN_ADD_REQUEST:
      return { loadingEntretienAdd: true };
    case ENTRETIEN_ADD_SUCCESS:
      toast.success("Cette Entretien a été ajouté avec succès");
      return {
        loadingEntretienAdd: false,
        successEntretienAdd: true,
      };
    case ENTRETIEN_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingEntretienAdd: false,
        successEntretienAdd: false,
        errorEntretienAdd: action.payload,
      };
    default:
      return state;
  }
};

export const deleteEntretienReducer = (state = {}, action) => {
  switch (action.type) {
    case ENTRETIEN_DELETE_REQUEST:
      return { loadingEntretienDelete: true };
    case ENTRETIEN_DELETE_SUCCESS:
      toast.success("Ce Entretien a été supprimer avec succès");
      return {
        loadingEntretienDelete: false,
        successEntretienDelete: true,
      };
    case ENTRETIEN_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingEntretienDelete: false,
        successEntretienDelete: false,
        errorEntretienDelete: action.payload,
      };
    default:
      return state;
  }
};

export const entretienListReducer = (state = { entretiens: [] }, action) => {
  switch (action.type) {
    case ENTRETIEN_LIST_REQUEST:
      return { loadingEntretient: true, entretiens: [] };
    case ENTRETIEN_LIST_SUCCESS:
      return {
        loadingEntretient: false,
        successEntretient: true,
        entretiens: action.payload,
      };
    case ENTRETIEN_LIST_FAIL:
      return {
        loadingEntretient: false,
        successEntretient: false,
        errorEntretient: action.payload,
      };
    default:
      return state;
  }
};

//
export const updateChargeReducer = (state = {}, action) => {
  switch (action.type) {
    case CHARGE_UPDATE_REQUEST:
      return { loadingChargeUpdate: true };
    case CHARGE_UPDATE_SUCCESS:
      toast.success("Ce Charge a été modifé avec succès");
      return {
        loadingChargeUpdate: false,
        successChargeUpdate: true,
      };
    case CHARGE_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingChargeUpdate: false,
        successChargeUpdate: false,
        errorChargeUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const deleteChargeReducer = (state = {}, action) => {
  switch (action.type) {
    case CHARGE_DELETE_REQUEST:
      return { loadingChargeDelete: true };
    case CHARGE_DELETE_SUCCESS:
      toast.success("Ce Charge a été supprimer avec succès");
      return {
        loadingChargeDelete: false,
        successChargeDelete: true,
      };
    case CHARGE_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingChargeDelete: false,
        successChargeDelete: false,
        errorChargeDelete: action.payload,
      };
    default:
      return state;
  }
};

export const createNewChargeReducer = (state = {}, action) => {
  switch (action.type) {
    case CHARGE_ADD_REQUEST:
      return { loadingChargeAdd: true };
    case CHARGE_ADD_SUCCESS:
      toast.success("Cette Charge a été ajouté avec succès");
      return {
        loadingChargeAdd: false,
        successChargeAdd: true,
      };
    case CHARGE_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingChargeAdd: false,
        successChargeAdd: false,
        errorChargeAdd: action.payload,
      };
    default:
      return state;
  }
};

export const chargeListReducer = (state = { charges: [] }, action) => {
  switch (action.type) {
    case CHARGE_LIST_REQUEST:
      return { loadingCharge: true, charges: [] };
    case CHARGE_LIST_SUCCESS:
      return {
        loadingCharge: false,
        successCharge: true,
        charges: action.payload,
      };
    case CHARGE_LIST_FAIL:
      return {
        loadingCharge: false,
        successCharge: false,
        errorCharge: action.payload,
      };
    default:
      return state;
  }
};
