export const CAR_LIST_REQUEST = "CAR_LIST_REQUEST";
export const CAR_LIST_SUCCESS = "CAR_LIST_SUCCESS";
export const CAR_LIST_FAIL = "CAR_LIST_FAIL";

export const CAR_ADD_REQUEST = "CAR_ADD_REQUEST";
export const CAR_ADD_SUCCESS = "CAR_ADD_SUCCESS";
export const CAR_ADD_FAIL = "CAR_ADD_FAIL";

export const CAR_DETAIL_REQUEST = "CAR_DETAIL_REQUEST";
export const CAR_DETAIL_SUCCESS = "CAR_DETAIL_SUCCESS";
export const CAR_DETAIL_FAIL = "CAR_DETAIL_FAIL";

export const CAR_UPDATE_REQUEST = "CAR_UPDATE_REQUEST";
export const CAR_UPDATE_SUCCESS = "CAR_UPDATE_SUCCESS";
export const CAR_UPDATE_FAIL = "CAR_UPDATE_FAIL";
