import axios from "axios";
import { baseURL } from "./constants";



// this online
// const baseURL = "http://15.188.52.23/api";
// const baseURLFile = "http://15.188.52.23/";

// const baseURL = "http://backend.ctitechnologie.ma";

const instance = axios.create({
    timeout: 5000,
    baseURL: baseURL,
});
export default instance;

