export const PRESCRIBER_LIST_REQUEST = "PRESCRIBER_LIST_REQUEST";
export const PRESCRIBER_LIST_SUCCESS = "PRESCRIBER_LIST_SUCCESS";
export const PRESCRIBER_LIST_FAIL = "PRESCRIBER_LIST_FAIL";

export const PRESCRIBER_ADD_REQUEST = "PRESCRIBER_ADD_REQUEST";
export const PRESCRIBER_ADD_SUCCESS = "PRESCRIBER_ADD_SUCCESS";
export const PRESCRIBER_ADD_FAIL = "PRESCRIBER_ADD_FAIL";

export const PRESCRIBER_DETAIL_REQUEST = "PRESCRIBER_DETAIL_REQUEST";
export const PRESCRIBER_DETAIL_SUCCESS = "PRESCRIBER_DETAIL_SUCCESS";
export const PRESCRIBER_DETAIL_FAIL = "PRESCRIBER_DETAIL_FAIL";

export const PRESCRIBER_UPDATE_REQUEST = "PRESCRIBER_UPDATE_REQUEST";
export const PRESCRIBER_UPDATE_SUCCESS = "PRESCRIBER_UPDATE_SUCCESS";
export const PRESCRIBER_UPDATE_FAIL = "PRESCRIBER_UPDATE_FAIL";

export const PRESCRIBER_DELETE_REQUEST = "PRESCRIBER_DELETE_REQUEST";
export const PRESCRIBER_DELETE_SUCCESS = "PRESCRIBER_DELETE_SUCCESS";
export const PRESCRIBER_DELETE_FAIL = "PRESCRIBER_DELETE_FAIL";
