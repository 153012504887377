import { toast } from "react-toastify";
import {
  CONTRAT_LIST_REQUEST,
  CONTRAT_LIST_SUCCESS,
  CONTRAT_LIST_FAIL,
  //
  CONTRAT_ADD_REQUEST,
  CONTRAT_ADD_SUCCESS,
  CONTRAT_ADD_FAIL,
  //
  CONTRAT_DETAIL_REQUEST,
  CONTRAT_DETAIL_SUCCESS,
  CONTRAT_DETAIL_FAIL,
  //
  CONTRAT_UPDATE_REQUEST,
  CONTRAT_UPDATE_SUCCESS,
  CONTRAT_UPDATE_FAIL,
  //
} from "../constants/contratConstant";

export const updateContratReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRAT_UPDATE_REQUEST:
      return { loadingContratUpdate: true };
    case CONTRAT_UPDATE_SUCCESS:
      toast.success("Cette Contrat a été mis à jour avec succès");
      return {
        loadingContratUpdate: false,
        successContratUpdate: true,
      };
    case CONTRAT_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingContratUpdate: false,
        successContratUpdate: false,
        errorContratUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const detailContratReducer = (state = { contrat: {} }, action) => {
  switch (action.type) {
    case CONTRAT_DETAIL_REQUEST:
      return { loading: true };
    case CONTRAT_DETAIL_SUCCESS:
      return {
        loading: false,
        success: true,
        contrat: action.payload,
      };
    case CONTRAT_DETAIL_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createNewContratReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRAT_ADD_REQUEST:
      return { loadingContratAdd: true };
    case CONTRAT_ADD_SUCCESS:
      toast.success("Cette Contrat a été ajouté avec succès");
      return {
        loadingContratAdd: false,
        successContratAdd: true,
      };
    case CONTRAT_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingContratAdd: false,
        successContratAdd: false,
        errorContratAdd: action.payload,
      };
    default:
      return state;
  }
};

export const contratListReducer = (state = { contrats: [] }, action) => {
  switch (action.type) {
    case CONTRAT_LIST_REQUEST:
      return { loading: true, contrats: [] };
    case CONTRAT_LIST_SUCCESS:
      return {
        loading: false,
        contrats: action.payload.contrats,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CONTRAT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
