import { toast } from "react-toastify";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  //
  USER_ADD_SUCCESS,
  USER_ADD_REQUEST,
  USER_ADD_FAIL,
  //
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_FAIL,
  //
  USER_PROFILE_SUCCESS,
  USER_PROFILE_REQUEST,
  USER_PROFILE_FAIL,
  //
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_FAIL,
  //
  USER_DETAIL_SUCCESS,
  USER_DETAIL_REQUEST,
  USER_DETAIL_FAIL,
  //
  USER_UPDATE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_FAIL,
  //
} from "../constants/userConstants";

export const updateDetailUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loadingUserDetailUpdate: true };
    case USER_UPDATE_SUCCESS:
      return {
        loadingUserDetailUpdate: false,
        successUserDetailUpdate: true,
      };
    case USER_UPDATE_FAIL:
      return {
        loadingUserDetailUpdate: false,
        errorUserDetailUpdate: action.payload,
        successUserDetailUpdate: false,
      };
    default:
      return state;
  }
};

export const getDetailUserReducer = (state = { userDetail: {} }, action) => {
  switch (action.type) {
    case USER_DETAIL_REQUEST:
      return { loadingUserDetail: true };
    case USER_DETAIL_SUCCESS:
      return {
        loadingUserDetail: false,
        userDetail: action.payload.user,
        successUserDetail: true,
      };
    case USER_DETAIL_FAIL:
      return {
        loadingUserDetail: false,
        errorUserDetail: action.payload,
        successUserDetail: false,
      };
    default:
      return state;
  }
};

export const updateProfileUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_UPDATE_REQUEST:
      return { loadingUserProfileUpdate: true };
    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        loadingUserProfileUpdate: false,
        successUserProfileUpdate: true,
      };
    case USER_PROFILE_UPDATE_FAIL:
      return {
        loadingUserProfileUpdate: false,
        errorUsersProfileUpdate: action.payload,
        successUserProfileUpdate: false,
      };
    default:
      return state;
  }
};

export const getProfileUserReducer = (state = { userProfile: {} }, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return { loadingUserProfile: true };
    case USER_PROFILE_SUCCESS:
      return {
        loadingUserProfile: false,
        userProfile: action.payload.profile,
        successUserProfile: true,
      };
    case USER_PROFILE_FAIL:
      return {
        loadingUserProfile: false,
        errorUserProfile: action.payload,
        successUserProfile: false,
      };
    default:
      return state;
  }
};

export const createNewUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ADD_REQUEST:
      return { loadingUserAdd: true };
    case USER_ADD_SUCCESS:
      toast.success("Cet utilisateur a été ajouté avec succès");
      return {
        loadingUserAdd: false,
        successUserAdd: true,
      };
    case USER_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingUserAdd: false,
        successUserAdd: false,
        errorUserAdd: action.payload,
      };
    default:
      return state;
  }
};

export const usersListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loadingUsers: true, users: [] };
    case USER_LIST_SUCCESS:
      return {
        loadingUsers: false,
        users: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case USER_LIST_FAIL:
      return { loadingUsers: false, errorUsers: action.payload };
    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
