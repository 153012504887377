export const CHARGE_LIST_REQUEST = "CHARGE_LIST_REQUEST";
export const CHARGE_LIST_SUCCESS = "CHARGE_LIST_SUCCESS";
export const CHARGE_LIST_FAIL = "CHARGE_LIST_FAIL";

export const CHARGE_ADD_REQUEST = "CHARGE_ADD_REQUEST";
export const CHARGE_ADD_SUCCESS = "CHARGE_ADD_SUCCESS";
export const CHARGE_ADD_FAIL = "CHARGE_ADD_FAIL";

export const CHARGE_DELETE_REQUEST = "CHARGE_DELETE_REQUEST";
export const CHARGE_DELETE_SUCCESS = "CHARGE_DELETE_SUCCESS";
export const CHARGE_DELETE_FAIL = "CHARGE_DELETE_FAIL";

export const CHARGE_UPDATE_REQUEST = "CHARGE_UPDATE_REQUEST";
export const CHARGE_UPDATE_SUCCESS = "CHARGE_UPDATE_SUCCESS";
export const CHARGE_UPDATE_FAIL = "CHARGE_UPDATE_FAIL";

//
export const ENTRETIEN_LIST_REQUEST = "ENTRETIEN_LIST_REQUEST";
export const ENTRETIEN_LIST_SUCCESS = "ENTRETIEN_LIST_SUCCESS";
export const ENTRETIEN_LIST_FAIL = "ENTRETIEN_LIST_FAIL";

export const ENTRETIEN_DELETE_REQUEST = "ENTRETIEN_DELETE_REQUEST";
export const ENTRETIEN_DELETE_SUCCESS = "ENTRETIEN_DELETE_SUCCESS";
export const ENTRETIEN_DELETE_FAIL = "ENTRETIEN_DELETE_FAIL";

export const ENTRETIEN_ADD_REQUEST = "ENTRETIEN_ADD_REQUEST";
export const ENTRETIEN_ADD_SUCCESS = "ENTRETIEN_ADD_SUCCESS";
export const ENTRETIEN_ADD_FAIL = "ENTRETIEN_ADD_FAIL";

export const ENTRETIEN_UPDATE_REQUEST = "ENTRETIEN_UPDATE_REQUEST";
export const ENTRETIEN_UPDATE_SUCCESS = "ENTRETIEN_UPDATE_SUCCESS";
export const ENTRETIEN_UPDATE_FAIL = "ENTRETIEN_UPDATE_FAIL";

//
export const DEPENSE_CHARGE_LIST_REQUEST = "DEPENSE_CHARGE_LIST_REQUEST";
export const DEPENSE_CHARGE_LIST_SUCCESS = "DEPENSE_CHARGE_LIST_SUCCESS";
export const DEPENSE_CHARGE_LIST_FAIL = "DEPENSE_CHARGE_LIST_FAIL";

export const DEPENSE_CHARGE_ADD_REQUEST = "DEPENSE_CHARGE_ADD_REQUEST";
export const DEPENSE_CHARGE_ADD_SUCCESS = "DEPENSE_CHARGE_ADD_SUCCESS";
export const DEPENSE_CHARGE_ADD_FAIL = "DEPENSE_CHARGE_ADD_FAIL";

export const DEPENSE_CHARGE_DETAIL_REQUEST = "DEPENSE_CHARGE_DETAIL_REQUEST";
export const DEPENSE_CHARGE_DETAIL_SUCCESS = "DEPENSE_CHARGE_DETAIL_SUCCESS";
export const DEPENSE_CHARGE_DETAIL_FAIL = "DEPENSE_CHARGE_DETAIL_FAIL";

export const DEPENSE_CHARGE_UPDATE_REQUEST = "DEPENSE_CHARGE_UPDATE_REQUEST";
export const DEPENSE_CHARGE_UPDATE_SUCCESS = "DEPENSE_CHARGE_UPDATE_SUCCESS";
export const DEPENSE_CHARGE_UPDATE_FAIL = "DEPENSE_CHARGE_UPDATE_FAIL";

//
export const DEPENSE_ENTRETIEN_LIST_REQUEST = "DEPENSE_ENTRETIEN_LIST_REQUEST";
export const DEPENSE_ENTRETIEN_LIST_SUCCESS = "DEPENSE_ENTRETIEN_LIST_SUCCESS";
export const DEPENSE_ENTRETIEN_LIST_FAIL = "DEPENSE_ENTRETIEN_LIST_FAIL";

export const DEPENSE_ENTRETIEN_ADD_REQUEST = "DEPENSE_ENTRETIEN_ADD_REQUEST";
export const DEPENSE_ENTRETIEN_ADD_SUCCESS = "DEPENSE_ENTRETIEN_ADD_SUCCESS";
export const DEPENSE_ENTRETIEN_ADD_FAIL = "DEPENSE_ENTRETIEN_ADD_FAIL";

export const DEPENSE_ENTRETIEN_DETAIL_REQUEST =
  "DEPENSE_ENTRETIEN_DETAIL_REQUEST";
export const DEPENSE_ENTRETIEN_DETAIL_SUCCESS =
  "DEPENSE_ENTRETIEN_DETAIL_SUCCESS";
export const DEPENSE_ENTRETIEN_DETAIL_FAIL = "DEPENSE_ENTRETIEN_DETAIL_FAIL";

export const DEPENSE_ENTRETIEN_UPDATE_REQUEST =
  "DEPENSE_ENTRETIEN_UPDATE_REQUEST";
export const DEPENSE_ENTRETIEN_UPDATE_SUCCESS =
  "DEPENSE_ENTRETIEN_UPDATE_SUCCESS";
export const DEPENSE_ENTRETIEN_UPDATE_FAIL = "DEPENSE_ENTRETIEN_UPDATE_FAIL";

//
export const DEPENSE_EMPLOYE_LIST_REQUEST = "DEPENSE_EMPLOYE_LIST_REQUEST";
export const DEPENSE_EMPLOYE_LIST_SUCCESS = "DEPENSE_EMPLOYE_LIST_SUCCESS";
export const DEPENSE_EMPLOYE_LIST_FAIL = "DEPENSE_EMPLOYE_LIST_FAIL";

export const DEPENSE_EMPLOYE_ADD_REQUEST = "DEPENSE_EMPLOYE_ADD_REQUEST";
export const DEPENSE_EMPLOYE_ADD_SUCCESS = "DEPENSE_EMPLOYE_ADD_SUCCESS";
export const DEPENSE_EMPLOYE_ADD_FAIL = "DEPENSE_EMPLOYE_ADD_FAIL";

export const DEPENSE_EMPLOYE_DETAIL_REQUEST = "DEPENSE_EMPLOYE_DETAIL_REQUEST";
export const DEPENSE_EMPLOYE_DETAIL_SUCCESS = "DEPENSE_EMPLOYE_DETAIL_SUCCESS";
export const DEPENSE_EMPLOYE_DETAIL_FAIL = "DEPENSE_EMPLOYE_DETAIL_FAIL";

export const DEPENSE_EMPLOYE_UPDATE_REQUEST = "DEPENSE_EMPLOYE_UPDATE_REQUEST";
export const DEPENSE_EMPLOYE_UPDATE_SUCCESS = "DEPENSE_EMPLOYE_UPDATE_SUCCESS";
export const DEPENSE_EMPLOYE_UPDATE_FAIL = "DEPENSE_EMPLOYE_UPDATE_FAIL";
