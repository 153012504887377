export const CONTRAT_LIST_REQUEST = "CONTRAT_LIST_REQUEST";
export const CONTRAT_LIST_SUCCESS = "CONTRAT_LIST_SUCCESS";
export const CONTRAT_LIST_FAIL = "CONTRAT_LIST_FAIL";

export const CONTRAT_ADD_REQUEST = "CONTRAT_ADD_REQUEST";
export const CONTRAT_ADD_SUCCESS = "CONTRAT_ADD_SUCCESS";
export const CONTRAT_ADD_FAIL = "CONTRAT_ADD_FAIL";

export const CONTRAT_DETAIL_REQUEST = "CONTRAT_DETAIL_REQUEST";
export const CONTRAT_DETAIL_SUCCESS = "CONTRAT_DETAIL_SUCCESS";
export const CONTRAT_DETAIL_FAIL = "CONTRAT_DETAIL_FAIL";

export const CONTRAT_UPDATE_REQUEST = "CONTRAT_UPDATE_REQUEST";
export const CONTRAT_UPDATE_SUCCESS = "CONTRAT_UPDATE_SUCCESS";
export const CONTRAT_UPDATE_FAIL = "CONTRAT_UPDATE_FAIL";
