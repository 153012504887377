import "./App.css";
import "./axios.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginScreen from "./screens/auth/LoginScreen";
import LogoutScreen from "./screens/auth/LogoutScreen.js";
import DashboardScreen from "./screens/dashboard/DashboardScreen.js";
import ClientScreen from "./screens/client/ClientScreen.js";
import AddClientScreen from "./screens/client/AddClientScreen.js";
import EditClientScreen from "./screens/client/EditClientScreen.js";
import MarquesModelsScreen from "./screens/settings/marques-models/MarquesModelsScreen.js";
import EmployesScreen from "./screens/settings/employes/EmployesScreen.js";
import AddEmployeScreen from "./screens/settings/employes/AddEmployeScreen.js";
import EditEmployeScreen from "./screens/settings/employes/EditEmployeScreen.js";
import UserScreen from "./screens/settings/users/UserScreen.js";
import AddUserScreen from "./screens/settings/users/AddUserScreen.js";
import EditUserScreen from "./screens/settings/users/EditUserScreen.js";
import AgenceScreen from "./screens/agences/AgenceScreen.js";
import AddAgenceScreen from "./screens/agences/AddAgenceScreen.js";
import EditAgenceScreen from "./screens/agences/EditAgenceScreen.js";
import CarScreen from "./screens/car/CarScreen.js";
import AddCarScreen from "./screens/car/AddCarScreen.js";
import EditCarScreen from "./screens/car/EditCarScreen.js";
import ReservationScreen from "./screens/reservation/ReservationScreen.js";
import AddReservationScreen from "./screens/reservation/AddReservationScreen.js";
import EditReservationScreen from "./screens/reservation/EditReservationScreen.js";
import ContratScreen from "./screens/contrats/ContratScreen.js";
import AddContratScreen from "./screens/contrats/AddContratScreen.js";
import EditContratScreen from "./screens/contrats/EditContratScreen.js";
import DesignationScreen from "./screens/settings/designations/DesignationScreen.js";
import DepenseChargeScreen from "./screens/depenses/charges/DepenseChargeScreen.js";
import AddDepenseChargeScreen from "./screens/depenses/charges/AddDepenseChargeScreen.js";
import EditDepenseChargeScreen from "./screens/depenses/charges/EditDepenseChargeScreen.js";
import DepenseEntretienScreen from "./screens/depenses/entretiens/DepenseEntretienScreen.js";
import AddDepenseEntretienScreen from "./screens/depenses/entretiens/AddDepenseEntretienScreen.js";
import EditDepenseEntretienScreen from "./screens/depenses/entretiens/EditDepenseEntretienScreen.js";
import DepenseEmployeScreen from "./screens/depenses/employes/DepenseEmployeScreen.js";
import AddDepenseEmployeScreen from "./screens/depenses/employes/AddDepenseEmployeScreen.js";
import EditDepenseEmployeScreen from "./screens/depenses/employes/EditDepenseEmployeScreen.js";
import ProfileScreen from "./screens/profile/ProfileScreen.js";
import PrescripteurScreen from "./screens/prescripteur/PrescripteurScreen.js";
import AddPrescripteurScreen from "./screens/prescripteur/AddPrescripteurScreen.js";
import EditPrescripteurScreen from "./screens/prescripteur/EditPrescripteurScreen.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginScreen />,
  },
  {
    path: "/dashboard",
    element: <DashboardScreen />,
  },
  // client
  {
    path: "/clients",
    element: <ClientScreen />,
  },
  {
    path: "/clients/add",
    element: <AddClientScreen />,
  },
  {
    path: "/clients/edit/:id",
    element: <EditClientScreen />,
  },
  // prescripteur
  {
    path: "/prescripteurs",
    element: <PrescripteurScreen />,
  },
  {
    path: "/prescripteurs/add",
    element: <AddPrescripteurScreen />,
  },
  {
    path: "/prescripteurs/edit/:id",
    element: <EditPrescripteurScreen />,
  },
  // reservation
  {
    path: "/reservations",
    element: <ReservationScreen />,
  },
  {
    path: "/reservations/add",
    element: <AddReservationScreen />,
  },
  {
    path: "/reservations/edit/:id",
    element: <EditReservationScreen />,
  },
  // employes
  {
    path: "/users",
    element: <UserScreen />,
  },
  {
    path: "/users/add",
    element: <AddUserScreen />,
  },
  {
    path: "/users/edit/:id",
    element: <EditUserScreen />,
  },

  //
  {
    path: "/profile",
    element: <ProfileScreen />,
  },
  {
    path: "/logout",
    element: <LogoutScreen />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
