import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  createNewUserReducer,
  getDetailUserReducer,
  getProfileUserReducer,
  updateDetailUserReducer,
  updateProfileUserReducer,
  userLoginReducer,
  usersListReducer,
} from "./reducers/userReducers";
import {
  clientListReducer,
  createNewClientReducer,
  deleteClientReducer,
  detailClientReducer,
  updateClientReducer,
} from "./reducers/clientReducers";
import {
  addNewMarqueReducer,
  deleteMarqueReducer,
  marqueListReducer,
} from "./reducers/marqueReducers";
import {
  addNewModeleReducer,
  deleteModelReducer,
  modelListReducer,
} from "./reducers/modelReducers";
import {
  createNewEmployeReducer,
  detailEmployeReducer,
  employesListReducer,
  updateEmployeReducer,
} from "./reducers/employeReducers";
import {
  agenceListReducer,
  createNewAgenceReducer,
  getDetailAgenceReducer,
  updateAgenceReducer,
} from "./reducers/agenceReducers";
import {
  carListReducer,
  createNewCarReducer,
  detailCarReducer,
  updateCarReducer,
} from "./reducers/carReducers";
import {
  createNewReservationReducer,
  deleteReservationReducer,
  detailReservationReducer,
  findListReservationReducer,
  findListReservationTableReducer,
  reservationListReducer,
  updateReservationReducer,
} from "./reducers/reservationReducers";
import {
  contratListReducer,
  createNewContratReducer,
  detailContratReducer,
  updateContratReducer,
} from "./reducers/contratReducers";
import {
  chargeListReducer,
  createNewChargeReducer,
  createNewDepenseChargeReducer,
  createNewDepenseEmployeReducer,
  createNewDepenseEntretienReducer,
  createNewEntretienReducer,
  deleteChargeReducer,
  deleteEntretienReducer,
  depenseChargeListReducer,
  depenseEmployeListReducer,
  depenseEntretienListReducer,
  entretienListReducer,
  getDetailDepenseChargeReducer,
  getDetailDepenseEmployeReducer,
  getDetailDepenseEntretienReducer,
  updateChargeReducer,
  updateDepenseChargeReducer,
  updateDepenseEmployeReducer,
  updateDepenseEntretienReducer,
  updateEntretienReducer,
} from "./reducers/designationReducers";
import {
  createPrescriberReducer,
  deletePrescriberReducer,
  detailPrescriberReducer,
  getPrescriberListReducer,
  updatePrescriberReducer,
} from "./reducers/prescriberReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  //
  clientList: clientListReducer,
  createNewClient: createNewClientReducer,
  detailClient: detailClientReducer,
  updateClient: updateClientReducer,
  deleteClient: deleteClientReducer,
  //
  getPrescriberList: getPrescriberListReducer,
  createPrescriber: createPrescriberReducer,
  detailPrescriber: detailPrescriberReducer,
  updatePrescriber: updatePrescriberReducer,
  deletePrescriber: deletePrescriberReducer,
  //
  marqueList: marqueListReducer,
  addNewMarque: addNewMarqueReducer,
  deleteMarque: deleteMarqueReducer,
  //
  modelList: modelListReducer,
  deleteModel: deleteModelReducer,
  addNewModele: addNewModeleReducer,
  //
  employesList: employesListReducer,
  createNewEmploye: createNewEmployeReducer,
  detailEmploye: detailEmployeReducer,
  updateEmploye: updateEmployeReducer,
  //
  usersList: usersListReducer,
  createNewUser: createNewUserReducer,
  getProfileUser: getProfileUserReducer,
  updateProfileUser: updateProfileUserReducer,
  getDetailUser: getDetailUserReducer,
  updateDetailUser: updateDetailUserReducer,
  //
  agenceList: agenceListReducer,
  createNewAgence: createNewAgenceReducer,
  getDetailAgence: getDetailAgenceReducer,
  updateAgence: updateAgenceReducer,
  //
  carList: carListReducer,
  createNewCar: createNewCarReducer,
  detailCar: detailCarReducer,
  updateCar: updateCarReducer,
  //
  reservationList: reservationListReducer,
  createNewReservation: createNewReservationReducer,
  detailReservation: detailReservationReducer,
  updateReservation: updateReservationReducer,
  findListReservation: findListReservationReducer,
  findListReservationTable: findListReservationTableReducer,
  deleteReservation: deleteReservationReducer,
  //
  contratList: contratListReducer,
  createNewContrat: createNewContratReducer,
  detailContrat: detailContratReducer,
  updateContrat: updateContratReducer,
  //
  chargeList: chargeListReducer,
  createNewCharge: createNewChargeReducer,
  deleteCharge: deleteChargeReducer,
  updateCharge: updateChargeReducer,
  entretienList: entretienListReducer,
  deleteEntretien: deleteEntretienReducer,
  createNewEntretien: createNewEntretienReducer,
  updateEntretien: updateEntretienReducer,
  //
  depenseChargeList: depenseChargeListReducer,
  createNewDepenseCharge: createNewDepenseChargeReducer,
  getDetailDepenseCharge: getDetailDepenseChargeReducer,
  updateDepenseCharge: updateDepenseChargeReducer,
  //
  depenseEntretienList: depenseEntretienListReducer,
  createNewDepenseEntretien: createNewDepenseEntretienReducer,
  getDetailDepenseEntretien: getDetailDepenseEntretienReducer,
  updateDepenseEntretien: updateDepenseEntretienReducer,
  //
  depenseEmployeList: depenseEmployeListReducer,
  createNewDepenseEmploye: createNewDepenseEmployeReducer,
  getDetailDepenseEmploye: getDetailDepenseEmployeReducer,
  updateDepenseEmploye: updateDepenseEmployeReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfoEdenBack")
  ? JSON.parse(localStorage.getItem("userInfoEdenBack"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
