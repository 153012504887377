import { toast } from "react-toastify";
import {
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_FAIL,
  //
  CLIENT_ADD_REQUEST,
  CLIENT_ADD_SUCCESS,
  CLIENT_ADD_FAIL,
  //
  CLIENT_DETAIL_REQUEST,
  CLIENT_DETAIL_SUCCESS,
  CLIENT_DETAIL_FAIL,
  //
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAIL,
  //
  CLIENT_DELETE_REQUEST,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAIL,
  //
} from "../constants/clientConstants";

export const deleteClientReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_DELETE_REQUEST:
      return { loadingClientDelete: true };
    case CLIENT_DELETE_SUCCESS:
      toast.success("Ce Client a été supprimer avec succès");
      return {
        loadingClientDelete: false,
        successClientDelete: true,
      };
    case CLIENT_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingClientDelete: false,
        successClientDelete: false,
        errorClientDelete: action.payload,
      };
    default:
      return state;
  }
};

export const updateClientReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_UPDATE_REQUEST:
      return { loadingClientUpdate: true };
    case CLIENT_UPDATE_SUCCESS:
      toast.success("Ce Client a été mis à jour avec succès");
      return {
        loadingClientUpdate: false,
        successClientUpdate: true,
      };
    case CLIENT_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingClientUpdate: false,
        successClientUpdate: false,
        errorClientUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const detailClientReducer = (state = { client: {} }, action) => {
  switch (action.type) {
    case CLIENT_DETAIL_REQUEST:
      return { loading: true };
    case CLIENT_DETAIL_SUCCESS:
      return {
        loading: false,
        success: true,
        client: action.payload,
      };
    case CLIENT_DETAIL_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createNewClientReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_ADD_REQUEST:
      return { loadingClientAdd: true };
    case CLIENT_ADD_SUCCESS:
      toast.success("Ce Client a été ajouté avec succès");
      return {
        loadingClientAdd: false,
        successClientAdd: true,
      };
    case CLIENT_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingClientAdd: false,
        successClientAdd: false,
        errorClientAdd: action.payload,
      };
    default:
      return state;
  }
};

export const clientListReducer = (state = { clients: [] }, action) => {
  switch (action.type) {
    case CLIENT_LIST_REQUEST:
      return { loading: true, clients: [] };
    case CLIENT_LIST_SUCCESS:
      return {
        loading: false,
        clients: action.payload.clients,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CLIENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
