import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addNewClient, clientList } from "../../redux/actions/clientActions";
import LayoutSection from "../../components/LayoutSection";
import InputModel from "../../components/InputModel";
import ConfirmationModal from "../../components/ConfirmationModal";
import { addNewReservation } from "../../redux/actions/reservationActions";
import { getPrescriberList } from "../../redux/actions/prescriberActions";
import { TABLES, TABLETYPE } from "../../constants";

function AddReservationScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //
  //
  const [reservationDate, setReservationDate] = useState("");
  const [reservationDateError, setReservationDateError] = useState("");

  const [reservationTime, setReservationTime] = useState("");
  const [reservationTimeError, setReservationTimeError] = useState("");

  const [reservationPax, setReservationPax] = useState(1);
  const [reservationPaxError, setReservationPaxError] = useState("");

  const [reservationTypeTable, setReservationTypeTable] = useState("");
  const [reservationTypeTableError, setReservationTypeTableError] =
    useState("");

  const [reservationTable, setReservationTable] = useState("");
  const [reservationTableError, setReservationTableError] = useState("");

  const [prescriber, setPrescriber] = useState("");
  const [prescriberError, setPrescriberError] = useState("");

  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [status, setStatus] = useState("RESERVED");
  const [statusError, setStatusError] = useState("");

  const [aCompte, setACompte] = useState(0);
  const [aCompteError, setACompteError] = useState("");

  const [offert, setOffert] = useState("");
  const [offertError, setOffertError] = useState("");

  const [occassion, setOccassion] = useState("");
  const [occassionError, setOccassionError] = useState("");

  // client
  const [reservationClient, setReservationClient] = useState("");
  const [reservationClientError, setReservationClientError] = useState("");

  const [isClientExist, setIsClientExist] = useState(false);
  const [isClientExistError, setIsClientExistError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");

  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const [otherPhone, setOtherPhone] = useState("");
  const [otherPhoneError, setOtherPhoneError] = useState("");

  const [dateNaissance, setDateNaissance] = useState("");
  const [errorDateNaissance, setErrorDateNaissance] = useState("");

  const [langue, setLangue] = useState("");
  const [langueError, setLangueError] = useState("");

  const [statusClient, setStatusClient] = useState("");
  const [statusClientError, setStatusClientError] = useState("");

  const [type, setType] = useState("");
  const [typeError, setTypeError] = useState("");

  //

  const [isAddReservation, setIsAddReservation] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  //

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const listClient = useSelector((state) => state.clientList);
  const { clients } = listClient;

  const listPrescriber = useSelector((state) => state.getPrescriberList);
  const { prescribers, loadingPrescriber, errorPrescriber, pages } =
    listPrescriber;

  const addReservation = useSelector((state) => state.createNewReservation);
  const { loadingReservationAdd, errorReservationAdd, successReservationAdd } =
    addReservation;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(clientList("0"));
      dispatch(getPrescriberList("0"));
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    // Get data from URL query parameters
    const dateParam = queryParams.get("date");
    const tableTypeParam = queryParams.get("tabletype");
    const tableParam = queryParams.get("table");

    // Set state with URL query parameter values
    if (dateParam) setReservationDate(dateParam);
    if (tableTypeParam) setReservationTypeTable(tableTypeParam);
    if (tableParam) setReservationTable(tableParam);
  }, []);

  useEffect(() => {
    if (successReservationAdd) {
      setReservationDate("");
      setReservationDateError("");

      setReservationTime("");
      setReservationTimeError("");

      setReservationPax(1);
      setReservationPaxError("");

      setReservationTypeTable("");
      setReservationTypeTableError("");

      setReservationTable("");
      setReservationTableError("");

      setPrescriber("");
      setPrescriberError("");

      setNote("");
      setNoteError("");

      setStatus("RESERVED");
      setStatusError("");

      setACompte("");
      setACompteError("");

      setOffert("");
      setOffertError("");

      setOccassion("");
      setOccassionError("");

      // client
      setReservationClient("");
      setReservationClientError("");

      setIsClientExist("false");
      setIsClientExistError("");

      setFirstName("");
      setErrorFirstName("");

      setLastName("");
      setErrorLastName("");

      setEmail("");
      setEmailError("");

      setPhone("");
      setErrorPhone("");

      setOtherPhone("");
      setOtherPhoneError("");

      setDateNaissance("");
      setErrorDateNaissance("");

      setLangue("");
      setLangueError("");

      setStatusClient("");
      setStatusClientError("");
      dispatch(clientList("0"));
      dispatch(getPrescriberList("0"));

      setType("");
      setTypeError("");
      setIsAddReservation(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successReservationAdd]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/reservations/">
            <div className="">Réservations</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Nouveau</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Ajouter un nouveau réservation
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection
                title="Réservation"
                styles="bg-primary text-white font-bold"
              >
                {/*  */}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Date*"
                    type="date"
                    placeholder=""
                    min={new Date().toISOString().split("T")[0]}
                    value={reservationDate}
                    onChange={(v) => setReservationDate(v.target.value)}
                    error={reservationDateError}
                  />

                  <InputModel
                    label="Heure*"
                    type="time"
                    placeholder=""
                    min="22:00"
                    max="07:00"
                    value={reservationTime}
                    onChange={(v) => setReservationTime(v.target.value)}
                    error={reservationTimeError}
                  />
                </div>

                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Pax*"
                    type="select"
                    placeholder=""
                    value={reservationPax}
                    onChange={(v) => setReservationPax(v.target.value)}
                    error={reservationPaxError}
                    options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                      (nb, index) => ({
                        value: nb,
                        label: nb,
                      })
                    )}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Type Table*"
                    type="select"
                    placeholder=""
                    value={reservationTypeTable}
                    onChange={(v) => {
                      setReservationTypeTable(v.target.value);
                      setReservationTable("");
                    }}
                    error={reservationTypeTableError}
                    options={TABLETYPE?.map((type, index) => ({
                      value: type,
                      label: type,
                    }))}
                  />

                  <InputModel
                    label="Tables*"
                    type="select"
                    disabled={reservationTypeTable === ""}
                    placeholder=""
                    value={reservationTable}
                    onChange={(v) => setReservationTable(v.target.value)}
                    error={reservationTableError}
                    options={TABLES(reservationTypeTable)?.map(
                      (table, index) => ({
                        value: table.value,
                        label: table.value,
                      })
                    )}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Prescripteur"
                    type="select"
                    placeholder=""
                    value={prescriber}
                    onChange={(v) => setPrescriber(v.target.value)}
                    error={prescriberError}
                    options={prescribers?.map((prescrib, index) => ({
                      value: prescrib.id,
                      label: prescrib.first_name + " " + prescrib.last_name,
                    }))}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Remarque"
                    type="textarea"
                    placeholder=""
                    value={note}
                    onChange={(v) => setNote(v.target.value)}
                    error={noteError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Etat*"
                    type="select"
                    placeholder=""
                    value={status}
                    onChange={(v) => setStatus(v.target.value)}
                    error={statusError}
                    options={[
                      {
                        value: "RESERVED",
                        label: "RESERVED",
                      },
                      {
                        value: "CHECK",
                        label: "CHECK",
                      },
                      {
                        value: "CANCEL",
                        label: "CANCEL",
                      },
                      {
                        value: "NO SHOW",
                        label: "NO SHOW",
                      },
                    ]}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Acompte"
                    type="number"
                    isPrice={true}
                    placeholder=""
                    value={aCompte}
                    onChange={(v) => setACompte(v.target.value)}
                    error={aCompteError}
                  />
                </div>

                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Offert"
                    type="text"
                    placeholder=""
                    value={offert}
                    onChange={(v) => setOffert(v.target.value)}
                    error={offertError}
                  />

                  <InputModel
                    label="Occassion"
                    type="text"
                    placeholder=""
                    value={occassion}
                    onChange={(v) => setOccassion(v.target.value)}
                    error={occassionError}
                  />
                </div>
                {/*  */}
              </LayoutSection>
            </div>
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection
                title="Client"
                styles="bg-danger text-white font-bold"
              >
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="client existe déjà"
                    type="select"
                    placeholder=""
                    value={isClientExist}
                    onChange={(v) => {
                      setIsClientExist(v.target.value === "true");
                      setEmail("");
                      setFirstName("");
                      setLastName("");
                      setPhone("");
                      setOtherPhone("");
                      setLangue("");
                      setDateNaissance("");
                    }}
                    error={isClientExistError}
                    options={[
                      {
                        value: "false",
                        label: "Non",
                      },
                      {
                        value: "true",
                        label: "Oui",
                      },
                    ]}
                  />
                </div>
                {isClientExist === true ? (
                  <div className="md:py-2 md:flex ">
                    <InputModel
                      label="Client*"
                      type="select"
                      placeholder=""
                      value={reservationClient}
                      onChange={(v) => {
                        setReservationClient(v.target.value);
                        setEmail("");
                        setFirstName("");
                        setLastName("");
                        setPhone("");
                        setOtherPhone("");
                        setLangue("");
                        setDateNaissance("");
                        if (v.target.value !== "") {
                          console.log(v.target.value);
                          var filter = clients?.find(
                            (client) =>
                              parseInt(v.target.value) === parseInt(client.id)
                          );
                          if (filter !== null && filter !== undefined) {
                            setEmail(filter.email || ""); // Ensure setEmail always gets a defined value
                            setFirstName(filter.first_name || "");
                            setLastName(filter.last_name || "");
                            setPhone(filter.phone || "");
                            setOtherPhone(filter.other_phone || "");
                            setLangue(filter.langue || "");
                            setDateNaissance(filter.date_birth || "");
                          }
                        }
                      }}
                      error={reservationClientError}
                      options={clients?.map((client, index) => ({
                        value: client.id,
                        label: client.first_name + " " + client.last_name,
                      }))}
                    />
                  </div>
                ) : null}

                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Email*"
                    type="email"
                    placeholder=""
                    disabled={isClientExist}
                    value={email}
                    onChange={(v) => setEmail(v.target.value)}
                    error={emailError}
                  />
                </div>
                {/* fisrt name & last name */}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Nom*"
                    type="text"
                    placeholder=""
                    disabled={isClientExist}
                    value={firstName}
                    onChange={(v) => setFirstName(v.target.value)}
                    error={errorFirstName}
                  />

                  <InputModel
                    label="Prénom"
                    type="text"
                    placeholder=""
                    disabled={isClientExist}
                    value={lastName}
                    onChange={(v) => setLastName(v.target.value)}
                    error={errorLastName}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Mobile"
                    type="text"
                    placeholder=""
                    disabled={isClientExist}
                    value={otherPhone}
                    onChange={(v) => setOtherPhone(v.target.value)}
                    error={otherPhoneError}
                  />
                  <InputModel
                    label="Téléphone"
                    type="text"
                    placeholder=""
                    disabled={isClientExist}
                    value={phone}
                    onChange={(v) => setPhone(v.target.value)}
                    error={errorPhone}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Langue"
                    type="text"
                    placeholder=""
                    disabled={isClientExist}
                    value={langue}
                    onChange={(v) => setLangue(v.target.value)}
                    error={langueError}
                  />
                </div>

                {/* <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Statut"
                    type="text"
                    placeholder=""
                    value={status}
                    onChange={(v) => setStatus(v.target.value)}
                    error={statusError}
                  />
                </div> */}

                {/* date and nation */}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Date de naissance"
                    type="date"
                    placeholder=""
                    disabled={isClientExist}
                    value={dateNaissance}
                    onChange={(v) => {
                      if (v.target.value !== "") {
                        const parsedDate = new Date(v.target.value);
                        setDateNaissance(
                          parsedDate.toISOString().split("T")[0]
                        );
                      }
                    }}
                    error={errorDateNaissance}
                  />
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAddReservation(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Annuler
            </button>
            <button
              onClick={async () => {
                var check = true;
                setReservationDateError("");
                setReservationTimeError("");
                setReservationPaxError("");
                setReservationTypeTableError("");
                setReservationTableError("");
                setPrescriberError("");
                setNoteError("");
                setStatusError("");
                setACompteError("");
                setOffertError("");
                setOccassionError("");
                setErrorFirstName("");
                setErrorLastName("");
                setEmailError("");
                setErrorPhone("");
                setOtherPhoneError("");
                setErrorDateNaissance("");
                setLangueError("");
                setStatusClientError("");
                setTypeError("");

                if (reservationDate === "") {
                  setReservationDateError("Ce champ est requis.");
                  check = false;
                }
                if (reservationTime === "") {
                  setReservationTimeError("Ce champ est requis.");
                  check = false;
                }
                if (reservationPax === "") {
                  setReservationPaxError("Ce champ est requis.");
                  check = false;
                }
                if (reservationTypeTable === "") {
                  setReservationTypeTableError("Ce champ est requis.");
                  check = false;
                } else if (!TABLETYPE.includes(reservationTypeTable)) {
                  setReservationTypeTableError("Ce champ est requis.");
                  check = false;
                }
                if (reservationTable === "") {
                  setReservationTableError("Ce champ est requis.");
                  check = false;
                }
                if (status === "") {
                  setStatusError("Ce champ est requis.");
                  check = false;
                }
                // client
                setIsClientExistError("");
                if (isClientExist === true && reservationClient === "") {
                  setReservationClientError("Ce champ est requis.");
                  check = false;
                } else {
                  if (firstName === "") {
                    setErrorFirstName("Ce champ est requis.");
                    check = false;
                  }
                  if (email === "") {
                    setEmailError("Ce champ est requis.");
                    check = false;
                  }
                }

                if (check) {
                  setEventType("add");
                  setIsAddReservation(true);
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Ajouter
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAddReservation}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir ajouter cette Reservation ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setReservationDate("");
              setReservationDateError("");

              setReservationTime("");
              setReservationTimeError("");

              setReservationPax(1);
              setReservationPaxError("");

              setReservationTypeTable("");
              setReservationTypeTableError("");

              setReservationTable("");
              setReservationTableError("");

              setPrescriber("");
              setPrescriberError("");

              setNote("");
              setNoteError("");

              setStatus("RESERVED");
              setStatusError("");

              setACompte("");
              setACompteError("");

              setOffert("");
              setOffertError("");

              setOccassion("");
              setOccassionError("");

              // client
              setReservationClient("");
              setReservationClientError("");

              setIsClientExist("false");
              setIsClientExistError("");

              setFirstName("");
              setErrorFirstName("");

              setLastName("");
              setErrorLastName("");

              setEmail("");
              setEmailError("");

              setPhone("");
              setErrorPhone("");

              setOtherPhone("");
              setOtherPhoneError("");

              setDateNaissance("");
              setErrorDateNaissance("");

              setLangue("");
              setLangueError("");

              setStatusClient("");
              setStatusClientError("");

              setType("");
              setTypeError("");

              setIsAddReservation(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                addNewReservation({
                  client: isClientExist ? reservationClient : "",
                  reservation_date: reservationDate,
                  reservation_time: reservationTime,
                  nbr_pax: reservationPax,
                  table_type: reservationTypeTable,
                  reservation_table: reservationTable ?? "",
                  prescriber: prescriber ?? "",
                  note: note ?? "",
                  status: status ?? 1,
                  avence_payment: isNaN(parseFloat(aCompte))
                    ? 0
                    : parseFloat(aCompte) ?? 0,
                  offert: offert ?? "",
                  occasion: occassion ?? "",
                  first_name: firstName ?? "",
                  last_name: lastName ?? "",
                  date_birth: dateNaissance ?? "",
                  other_phone: otherPhone ?? "",
                  phone: phone ?? "",
                  email: email ?? "",
                  langue: langue ?? "",
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAddReservation(false);
            }
          }}
          onCancel={() => {
            setIsAddReservation(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />

        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default AddReservationScreen;
