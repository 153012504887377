export const EMPLOYE_LIST_REQUEST = "EMPLOYE_LIST_REQUEST";
export const EMPLOYE_LIST_SUCCESS = "EMPLOYE_LIST_SUCCESS";
export const EMPLOYE_LIST_FAIL = "EMPLOYE_LIST_FAIL";

export const EMPLOYE_ADD_REQUEST = "EMPLOYE_ADD_REQUEST";
export const EMPLOYE_ADD_SUCCESS = "EMPLOYE_ADD_SUCCESS";
export const EMPLOYE_ADD_FAIL = "EMPLOYE_ADD_FAIL";

export const EMPLOYE_DETAIL_REQUEST = "EMPLOYE_DETAIL_REQUEST";
export const EMPLOYE_DETAIL_SUCCESS = "EMPLOYE_DETAIL_SUCCESS";
export const EMPLOYE_DETAIL_FAIL = "EMPLOYE_DETAIL_FAIL";

export const EMPLOYE_UPDATE_REQUEST = "EMPLOYE_UPDATE_REQUEST";
export const EMPLOYE_UPDATE_SUCCESS = "EMPLOYE_UPDATE_SUCCESS";
export const EMPLOYE_UPDATE_FAIL = "EMPLOYE_UPDATE_FAIL";
