export const CLIENT_LIST_REQUEST = "CLIENT_LIST_REQUEST";
export const CLIENT_LIST_SUCCESS = "CLIENT_LIST_SUCCESS";
export const CLIENT_LIST_FAIL = "CLIENT_LIST_FAIL";

export const CLIENT_ADD_REQUEST = "CLIENT_ADD_REQUEST";
export const CLIENT_ADD_SUCCESS = "CLIENT_ADD_SUCCESS";
export const CLIENT_ADD_FAIL = "CLIENT_ADD_FAIL";

export const CLIENT_DETAIL_REQUEST = "CLIENT_DETAIL_REQUEST";
export const CLIENT_DETAIL_SUCCESS = "CLIENT_DETAIL_SUCCESS";
export const CLIENT_DETAIL_FAIL = "CLIENT_DETAIL_FAIL";

export const CLIENT_UPDATE_REQUEST = "CLIENT_UPDATE_REQUEST";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
export const CLIENT_UPDATE_FAIL = "CLIENT_UPDATE_FAIL";

export const CLIENT_DELETE_REQUEST = "CLIENT_DELETE_REQUEST";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_DELETE_FAIL = "CLIENT_DELETE_FAIL";
