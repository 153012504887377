import axios from "../../axios";
import {
  PRESCRIBER_LIST_REQUEST,
  PRESCRIBER_LIST_SUCCESS,
  PRESCRIBER_LIST_FAIL,
  //
  PRESCRIBER_ADD_REQUEST,
  PRESCRIBER_ADD_SUCCESS,
  PRESCRIBER_ADD_FAIL,
  //
  PRESCRIBER_DETAIL_REQUEST,
  PRESCRIBER_DETAIL_SUCCESS,
  PRESCRIBER_DETAIL_FAIL,
  //
  PRESCRIBER_UPDATE_REQUEST,
  PRESCRIBER_UPDATE_SUCCESS,
  PRESCRIBER_UPDATE_FAIL,
  //
  PRESCRIBER_DELETE_REQUEST,
  PRESCRIBER_DELETE_SUCCESS,
  PRESCRIBER_DELETE_FAIL,
  //
} from "../constants/prescriberConstants";

// delete
export const deletePrescriber = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRESCRIBER_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/prescribers/delete/${id}/`, config);

    dispatch({
      type: PRESCRIBER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRESCRIBER_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// update Prescriber
export const updatePrescriber =
  (id, prescriber) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRESCRIBER_UPDATE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put(
        `/prescribers/update/${id}/`,
        prescriber,
        config
      );

      dispatch({
        type: PRESCRIBER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PRESCRIBER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// detail Prescriber
export const detailPrescriber = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRESCRIBER_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/prescribers/detail/${id}/`, config);

    dispatch({
      type: PRESCRIBER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRESCRIBER_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new Prescriber
export const addNewPrescriber = (prescriber) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRESCRIBER_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(`/prescribers/add/`, prescriber, config);

    dispatch({
      type: PRESCRIBER_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRESCRIBER_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list Prescriber
export const getPrescriberList = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRESCRIBER_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/prescribers/?page=${page}`, config);

    dispatch({
      type: PRESCRIBER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRESCRIBER_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
