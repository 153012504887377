import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";

import bgvipf from "../../images/plan/vip-f.jpg";
import bgvipt from "../../images/plan/vip-t.jpg";
import bgbackstage from "../../images/plan/back-stage.jpg";
import bgdoncfloor from "../../images/plan/donce-floor.jpg";
import {
  ColorTable,
  TABLEBACKSTAGE,
  TABLEDONCEFLOOR,
  TABLEVIPF,
  TABLEVIPT,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  findListReservationTable,
  findListReservations,
} from "../../redux/actions/reservationActions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

function DashboardScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Month is 0-indexed, so add 1
  const day = ("0" + currentDate.getDate()).slice(-2);

  const [isShowReservation, setIsShowReservation] = useState(false);
  const [tableReserv, setTableReserv] = useState("");
  const [dateReserv, setDateReserv] = useState("");
  //
  const [statuSelect, setStatuSelect] = useState("ALL");
  const [dateSelect, setDateSelect] = useState(`${year}-${month}-${day}`);
  const [searchInput, setSearchInput] = useState("");

  const [tableType, setTableType] = useState("VIP 1");

  //
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listReservation = useSelector((state) => state.findListReservation);
  const { listReservations, loadingFindReservations, errorFindReservations } =
    listReservation;

  const tableListReservation = useSelector(
    (state) => state.findListReservationTable
  );
  const {
    listReservationTable,
    loadingFindReservationTable,
    errorFindReservationTable,
  } = tableListReservation;

  const redirect = "/";

  const reservationDelete = useSelector((state) => state.deleteReservation);
  const {
    loadingReservationDelete,
    errorReservationDelete,
    successReservationDelete,
  } = reservationDelete;

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(
        findListReservations(dateSelect, statuSelect, searchInput, tableType)
      );
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successReservationDelete) {
      dispatch(
        findListReservations(dateSelect, statuSelect, searchInput, tableType)
      );
    }
  }, [successReservationDelete, dispatch]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}

        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          {/* list */}
          <div className="flex md:flex-row flex-col justify-between">
            {/* date */}
            <div className="mt-2">
              <input
                type={"date"}
                placeholder={"Date"}
                style={{
                  boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
                  transition:
                    "border-color ease-in-out .15s, box-shadow ease-in-out .15s",
                }}
                className={` font-bold text-[14px] block w-full p-2 h-[34px] py-[6px] px-3 text-[#555] border-[#ccc] focus:border-[#ccc] focus:border rounded border`}
                value={dateSelect}
                onChange={(v) => {
                  setDateSelect(v.target.value);
                  if (v.target.value !== "") {
                    dispatch(
                      findListReservations(
                        v.target.value,
                        statuSelect,
                        searchInput,
                        tableType
                      )
                    );
                  }
                }}
              />
            </div>
            {/* search */}
            <div className="mt-2">
              <input
                type={"text"}
                placeholder={"Nom de client..."}
                style={{
                  boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
                  transition:
                    "border-color ease-in-out .15s, box-shadow ease-in-out .15s",
                }}
                className={` font-bold text-[14px] block w-full p-2 h-[34px] py-[6px] px-3 text-[#555] border-[#ccc] focus:border-[#ccc] focus:border rounded border`}
                value={searchInput}
                onChange={(v) => {
                  setSearchInput(v.target.value);

                  dispatch(
                    findListReservations(
                      dateSelect,
                      statuSelect,
                      v.target.value,
                      tableType
                    )
                  );
                }}
              />
            </div>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col justify-center mt-2">
            {/* status */}
            <div className="flex flex-row items-center justify-center">
              <div
                onClick={() => {
                  setStatuSelect("ALL");
                  dispatch(
                    findListReservations(
                      dateSelect,
                      "ALL",
                      searchInput,
                      tableType
                    )
                  );
                }}
                className={`px-3 md:text-base text-xs py-1 hover:bg-[#008000] ${
                  statuSelect === "ALL" ? "bg-[#008000]" : "bg-graydark"
                }  text-white font-bold cursor-pointer flex items-center`}
              >
                ALL <div className="text-xs mx-1"> (0) </div>
              </div>
              <div
                onClick={() => {
                  setStatuSelect("RESERVED");
                  dispatch(
                    findListReservations(
                      dateSelect,
                      "RESERVED",
                      searchInput,
                      tableType
                    )
                  );
                }}
                className={`px-3 md:text-base text-xs py-1 hover:bg-[#008000] ${
                  statuSelect === "RESERVED" ? "bg-[#008000]" : "bg-graydark"
                }  text-white font-bold cursor-pointer flex items-center`}
              >
                RESERVED <div className="text-xs mx-1"> (0) </div>
              </div>
              <div
                onClick={() => {
                  setStatuSelect("CHECK");
                  dispatch(
                    findListReservations(
                      dateSelect,
                      "CHECK",
                      searchInput,
                      tableType
                    )
                  );
                }}
                className={`px-3 md:text-base text-xs py-1 hover:bg-[#008000] ${
                  statuSelect === "CHECK" ? "bg-[#008000]" : "bg-graydark"
                }  text-white font-bold cursor-pointer flex items-center`}
              >
                CHECK <div className="text-xs mx-1"> (0) </div>
              </div>
              {/* <div
                onClick={() => {
                  setStatuSelect("CANCEL");
                  dispatch(
                    findListReservations(
                      dateSelect,
                      "CANCEL",
                      searchInput,
                      tableType
                    )
                  );
                }}
                className={`px-3 md:text-base text-xs py-1 hover:bg-[#008000] ${
                  statuSelect === "CANCEL" ? "bg-[#008000]" : "bg-graydark"
                }  text-white font-bold cursor-pointer flex items-center`}
              >
                CANCEL <div className="text-xs mx-1"> (0) </div>
              </div> */}
              <div
                onClick={() => {
                  setStatuSelect("NO SHOW");
                  dispatch(
                    findListReservations(
                      dateSelect,
                      "NO SHOW",
                      searchInput,
                      tableType
                    )
                  );
                }}
                className={`px-3 md:text-base text-xs py-1 hover:bg-[#008000] ${
                  statuSelect === "NO SHOW" ? "bg-[#008000]" : "bg-graydark"
                }  text-white font-bold cursor-pointer flex items-center`}
              >
                NO SHOW <div className="text-xs mx-1"> (0) </div>
              </div>
            </div>
          </div>

          {/* images */}
          <div className=" overflow-auto my-3">
            <div className="flex flex-row items-center w-[60rem] mx-auto bg-[#2f4f4f] ">
              <div
                onClick={() => {
                  setTableType("VIP 1");
                  dispatch(
                    findListReservations(
                      dateSelect,
                      statuSelect,
                      searchInput,
                      "VIP 1"
                    )
                  );
                }}
                className={`text-white cursor-pointer font-bold text-sm uppercase px-3 py-2 ${
                  tableType === "VIP 1" ? "bg-[#008000]" : ""
                } `}
              >
                VIP 1
              </div>
              <div
                onClick={() => {
                  setTableType("VIP 2");
                  dispatch(
                    findListReservations(
                      dateSelect,
                      statuSelect,
                      searchInput,
                      "VIP 2"
                    )
                  );
                }}
                className={`text-white cursor-pointer font-bold text-sm uppercase px-3 py-2 ${
                  tableType === "VIP 2" ? "bg-[#008000]" : ""
                } `}
              >
                VIP 2
              </div>
              <div
                onClick={() => {
                  setTableType("BACK STAGE");
                  dispatch(
                    findListReservations(
                      dateSelect,
                      statuSelect,
                      searchInput,
                      "BACK STAGE"
                    )
                  );
                }}
                className={`text-white cursor-pointer font-bold text-sm uppercase px-3 py-2 ${
                  tableType === "BACK STAGE" ? "bg-[#008000]" : ""
                } `}
              >
                BACK STAGE
              </div>
              <div
                onClick={() => {
                  setTableType("DANCE FLOOR");
                  dispatch(
                    findListReservations(
                      dateSelect,
                      statuSelect,
                      searchInput,
                      "DANCE FLOOR"
                    )
                  );
                }}
                className={`text-white cursor-pointer font-bold text-sm uppercase px-3 py-2 ${
                  tableType === "DANCE FLOOR" ? "bg-[#008000]" : ""
                } `}
              >
                DANCE FLOOR
              </div>
            </div>
            {/* image */}
            {tableType === "VIP 1" ? (
              <div className="flex flex-row items-center h-[30rem] w-[60rem] mx-auto bg-[#2f4f4f] relative ">
                {/* image vip 1 */}
                <img
                  src={bgvipf}
                  alt="VIP 1 PLAN"
                  className="w-full h-full bg-contain"
                />
                {/* table 1 */}
                {TABLEVIPF?.map((table, index) => (
                  <div
                    onClick={async () => {
                      setDateReserv(dateSelect);
                      setTableReserv(table.value);

                      // get list
                      await dispatch(
                        findListReservationTable(table.value, dateSelect)
                      ).then(() => {
                        setIsShowReservation(true);
                      });
                    }}
                    className={`absolute ${
                      table.position ?? ""
                    } cursor-pointer w-10 h-10 ${ColorTable(
                      table.value,
                      listReservations
                    )} rounded-full items-center text-center flex justify-center  text-sm`}
                  >
                    {table.number}
                  </div>
                ))}
              </div>
            ) : null}
            {tableType === "VIP 2" ? (
              <div className="flex flex-row items-center h-[30rem] w-[60rem] mx-auto bg-[#2f4f4f] relative ">
                {/* image vip 1 */}
                <img
                  src={bgvipt}
                  alt="VIP 2 PLAN"
                  className="w-full h-full bg-contain"
                />
                {/* table 1 */}
                {TABLEVIPT?.map((table, index) => (
                  <div
                    onClick={async () => {
                      setDateReserv(dateSelect);
                      setTableReserv(table.value);

                      // get list
                      await dispatch(
                        findListReservationTable(table.value, dateSelect)
                      ).then(() => {
                        setIsShowReservation(true);
                      });
                    }}
                    className={`absolute ${
                      table.position ?? ""
                    } cursor-pointer w-10 h-10  ${ColorTable(
                      table.value,
                      listReservations
                    )}  rounded-full items-center text-center flex justify-center  text-sm`}
                  >
                    {table.number}
                  </div>
                ))}
              </div>
            ) : null}
            {tableType === "BACK STAGE" ? (
              <div className="flex flex-row items-center h-[30rem] w-[60rem] mx-auto bg-[#2f4f4f] relative ">
                {/* image vip 1 */}
                <img
                  src={bgbackstage}
                  alt="BACK STAGE PLAN"
                  className="w-full h-full bg-contain"
                />
                {/* table 1 */}
                {TABLEBACKSTAGE?.map((table, index) => (
                  <div
                    onClick={async () => {
                      setDateReserv(dateSelect);
                      setTableReserv(table.value);
                      // get list
                      await dispatch(
                        findListReservationTable(table.value, dateSelect)
                      ).then(() => {
                        setIsShowReservation(true);
                      });
                    }}
                    className={`absolute ${
                      table.position ?? ""
                    } cursor-pointer w-10 h-10  ${ColorTable(
                      table.value,
                      listReservations
                    )}  rounded-full items-center text-center flex justify-center  text-sm`}
                  >
                    {table.number}
                  </div>
                ))}
              </div>
            ) : null}
            {tableType === "DANCE FLOOR" ? (
              <div className="flex flex-row items-center h-[30rem] w-[60rem] mx-auto bg-[#2f4f4f] relative ">
                {/* image vip 1 */}
                <img
                  src={bgdoncfloor}
                  alt="DANCE FLOOR PLAN"
                  className="w-full h-full bg-contain"
                />
                {/* table 1 */}
                {TABLEDONCEFLOOR?.map((table, index) => (
                  <div
                    onClick={async () => {
                      setDateReserv(dateSelect);
                      setTableReserv(table.value);

                      // get list
                      await dispatch(
                        findListReservationTable(table.value, dateSelect)
                      ).then(() => {
                        setIsShowReservation(true);
                      });
                    }}
                    className={`absolute ${
                      table.position ?? ""
                    } cursor-pointer w-10 h-10  ${ColorTable(
                      table.value,
                      listReservations
                    )}  rounded-full items-center text-center flex justify-center  text-sm`}
                  >
                    {table.number}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          {/*  */}
          <div className="mt-2">
            {loadingFindReservations ? (
              <Loader />
            ) : errorFindReservations ? (
              <Alert type="error" message={errorFindReservations} />
            ) : (
              <div className="max-w-full overflow-x-auto mt-3">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-2 text-left ">
                      <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                        NR
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Date
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Heure
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Client
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        N-Personnes
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Tables
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Prescripteur
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Statut
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Avance
                      </th>

                      <th className="py-4 px-4 font-bold text-black text-xs w-max">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {/*  */}
                  <tbody>
                    {listReservations?.map((reservation, index) => (
                      <tr key={index}>
                        <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.id}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.reservation_date ?? ""}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.reservation_time ?? ""}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.client?.first_name ?? "---"}{" "}
                            {reservation.client?.last_name ?? "---"}
                          </p>
                        </td>

                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.nbr_pax ?? ""}
                          </p>
                        </td>

                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.reservation_table ?? ""} -{" "}
                            {reservation.table_type ?? ""}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.prescriber?.first_name ?? "---"}{" "}
                            {reservation.prescriber?.last_name ?? "---"}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.status ?? ""}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {parseFloat(reservation.avence_payment).toFixed(
                              2
                            ) ?? ""}
                          </p>
                        </td>

                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max flex flex-row  ">
                            {/* edit */}
                            <Link
                              className="mx-1 update-class"
                              to={"/reservations/edit/" + reservation.id}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                />
                              </svg>
                            </Link>
                          </p>
                        </td>
                      </tr>
                    ))}
                    <tr className="h-11"></tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="h-20"></div>
        </div>

        {/* buttom dash */}
        {/* reservation table */}
        {isShowReservation ? (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-99999 bg-black bg-opacity-20 ">
            <div className="bg-white p-6 rounded shadow-md md:max-w-[70%] overflow-y-auto  max-h-[70%] max-w-[95%]">
              <h3 className="text-lg font-bold mb-4">
                Rechercher des réservations
              </h3>
              <p className="mb-4 mt-3 flex md:flex-row flex-col md:justify-between items-center">
                <div>Date : {dateReserv}</div>
                <div>Table : {tableReserv}</div>
              </p>
              <div>
                {loadingFindReservationTable ? (
                  <Loader />
                ) : errorFindReservationTable ? (
                  <Alert type="error" message={errorFindReservationTable} />
                ) : (
                  <div className="max-w-full overflow-x-auto mt-3">
                    <table className="w-full table-auto">
                      <thead>
                        <tr className="bg-gray-2 text-left ">
                          <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                            NR
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                            Date
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                            Heure
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                            Client
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                            N-Personnes
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                            Tables
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                            Prescripteur
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                            Statut
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                            Avance
                          </th>

                          <th className="py-4 px-4 font-bold text-black text-xs w-max">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      {/*  */}
                      <tbody>
                        {listReservationTable?.map((reservation, index) => (
                          <tr key={index}>
                            <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                              <p className="text-black  text-xs w-max  ">
                                {reservation.id}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {reservation.reservation_date ?? ""}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {reservation.reservation_time ?? ""}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {reservation.client?.first_name ?? "---"}{" "}
                                {reservation.client?.last_name ?? "---"}
                              </p>
                            </td>

                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {reservation.nbr_pax ?? ""}
                              </p>
                            </td>

                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {reservation.reservation_table ?? ""} -{" "}
                                {reservation.table_type ?? ""}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {reservation.prescriber?.first_name ?? "---"}{" "}
                                {reservation.prescriber?.last_name ?? "---"}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {reservation.status ?? ""}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {parseFloat(reservation.avence_payment).toFixed(
                                  2
                                ) ?? ""}
                              </p>
                            </td>

                            <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max flex flex-row  ">
                                {/* edit */}
                                <Link
                                  className="mx-1 update-class"
                                  to={"/reservations/edit/" + reservation.id}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                    />
                                  </svg>
                                </Link>
                              </p>
                            </td>
                          </tr>
                        ))}
                        {/*  */}
                        {listReservationTable.length === 0 ? (
                          <tr>
                            <td colSpan={10}>
                              <p className="text-left text-sm">
                                Il n'y a pas de réservation pour cette table{" "}
                                <b> {tableReserv}</b> à cette date{" "}
                                <b> {dateReserv}</b>
                              </p>
                              <p className="text-left text-sm ">
                                Vous pouvez ajouter une nouvelle réservation en
                                cliquant sur
                                <b>
                                  <a
                                    href={`/reservations/add?date=${dateReserv}&tabletype=${tableType}&table=${tableReserv}`}
                                  >
                                    {" "}
                                    Ajouter
                                  </a>
                                </b>
                              </p>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td></td>
                          </tr>
                        )}
                        <tr className="h-11"></tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              <div className="flex justify-end">
                {listReservationTable.length === 0 ? (
                  <a
                    href={`/reservations/add?date=${dateReserv}&tabletype=${tableType}&table=${tableReserv}`}
                    className="bg-primary hover:bg-gray-600 text-white font-bold py-2 px-4 rounded mx-1"
                    onClick={() => {
                      setIsShowReservation(false);
                    }}
                    // disabled={loadEvent}
                  >
                    Ajouter
                  </a>
                ) : null}
                <button
                  className="bg-danger hover:bg-gray-600 text-white font-bold py-2 px-4 rounded mx-1"
                  onClick={() => {
                    setIsShowReservation(false);
                  }}
                  // disabled={loadEvent}
                >
                  {" "}
                  Annuler{" "}
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {/*  */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default DashboardScreen;
