import { toast } from "react-toastify";

import {
  MARQUE_LIST_REQUEST,
  MARQUE_LIST_SUCCESS,
  MARQUE_LIST_FAIL,
  //
  MARQUE_ADD_REQUEST,
  MARQUE_ADD_SUCCESS,
  MARQUE_ADD_FAIL,
  //
  MARQUE_DELETE_REQUEST,
  MARQUE_DELETE_SUCCESS,
  MARQUE_DELETE_FAIL,
  //
} from "../constants/marqueConstants";

export const deleteMarqueReducer = (state = {}, action) => {
  switch (action.type) {
    case MARQUE_DELETE_REQUEST:
      return { loadingMarqueDelete: true };
    case MARQUE_DELETE_SUCCESS:
      toast.success("Cette marque a été supprimé avec succès");
      return {
        loadingMarqueDelete: false,
        successMarqueDelete: true,
      };
    case MARQUE_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingMarqueDelete: false,
        successMarqueDelete: false,
        errorMarqueDelete: action.payload,
      };
    default:
      return state;
  }
};

export const addNewMarqueReducer = (state = {}, action) => {
  switch (action.type) {
    case MARQUE_ADD_REQUEST:
      return { loadingMarqueAdd: true };
    case MARQUE_ADD_SUCCESS:
      toast.success("Cette Marque a été ajouté avec succès");
      return {
        loadingMarqueAdd: false,
        successMarqueAdd: true,
      };
    case MARQUE_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingMarqueAdd: false,
        successMarqueAdd: false,
        errorMarqueAdd: action.payload,
      };
    default:
      return state;
  }
};

export const marqueListReducer = (state = { marques: [] }, action) => {
  switch (action.type) {
    case MARQUE_LIST_REQUEST:
      return { loadingMarque: true, marques: [] };
    case MARQUE_LIST_SUCCESS:
      return {
        loadingMarque: false,
        marques: action.payload,
      };
    case MARQUE_LIST_FAIL:
      return { loadingMarque: false, errorMarque: action.payload };
    default:
      return state;
  }
};
